import { all } from "redux-saga/effects";
import { createDriver } from "redux-saga-requests-axios";
import { createRequestInstance, watchRequests } from "redux-saga-requests";

import authSaga from "./auth/ducks/saga";
import { dashboardSaga } from "./dashboard";
import { buildingsSaga } from "./buildings";
import axiosInstance from "./axios";
import interceptors from "./interceptors";
import { notesSaga } from "./notes";

export default function* rootSaga() {
  yield all([
    authSaga(),
    buildingsSaga(),
    dashboardSaga(),
    notesSaga(),
    createRequestInstance({
      ...interceptors,
      driver: createDriver(axiosInstance)
    }),
    watchRequests()
  ]);
}
