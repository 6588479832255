import React from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import {
  Done,
  WatchLater,
  Warning,
  Build
} from "@material-ui/icons";
import { isEmpty } from "lodash";
import * as Messages from "./Messages";

const useStyles = makeStyles(theme => ({
  yes: {
    color: "green"
  },
  no: {
    color: "red"
  },
  to_be_integrated: {
    color: "orange"
  },
  near_to_expire: {
    color: "orange"
  },
  expired: {
    color: "red"
  },
  missing: {
    color: "lightgray"
  },
  optional: {
    color: "blue"
  }
}));

const status = {
  yes: {
    title: Messages.Yes,
    Icon: Done
  },
  no: {
    title: Messages.No,
    Icon: Warning
  },
  to_be_integrated: {
    title: Messages.ToBeIntegrated,
    Icon: Build
  },
  near_to_expire: {
    title: Messages.NearToExpire,
    Icon: WatchLater
  },
  expired: {
    title: Messages.Expired,
    Icon: WatchLater
  },
  missing: {
    title: Messages.Missing,
    Icon: () => null
  }
};

const Status = ({ value, required = true, ...props }) => {
  const classes = useStyles();
  const notNullValue = isEmpty(value) ? "no" : value;
  const { title, Icon } = status[notNullValue];
  return (
    <Tooltip title={title} placement="top">
      <Icon
        {...props}
        className={classes[!required && notNullValue === "no" ? "optional" : notNullValue]}
      />
    </Tooltip>
  );
};

Status.defaultProps = {
  value: "no"
};

export default Status;
