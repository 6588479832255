import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import {
  building,
  missingActivities,
  isLoading,
  isError,
  groupedActivities
} from "../ducks/selectors";
import BuildingDetailComponent from "./BuildingDetailComponent";
import {
  startGetBuildingSaga,
  createBuildingActivities,
  toggleAssignedState
} from "../ducks/actions";
import { saveBuildingActivity, deleteBuildingActivity } from "../../building-activities";
import { associatedManagers, getBuildingManagers, getManagers } from "../../managers";

import { user } from "../../auth";

const enhance = compose(
  withRouter,
  connect(
    state => ({
      building: building(state),
      associatedManagers: associatedManagers(state),
      buildingActivities: groupedActivities(state),
      missingActivities: missingActivities(state),
      user: user(state),
      error: isError(state),
      loading: isLoading(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          deleteBuildingActivity,
          saveBuildingActivity,
          startGetBuildingSaga,
          getBuildingManagers,
          getManagers,
          createBuildingActivities,
          toggleAssignedState
        },
        dispatch
      )
    })
  ),
  lifecycle({
    componentDidMount() {
      const { actions, user, match } = this.props;
      actions.startGetBuildingSaga({ buildingId: match.params.id });
      actions.getBuildingManagers({ buildingId: match.params.id });
      actions.getManagers({ referId: user.referId, userType: user.userType });
    }
  }),
  withHandlers({
    onRefresh: ({ actions, match }) => () =>
      actions.startGetBuildingSaga({ buildingId: match.params.id })
  })
);

export default enhance(BuildingDetailComponent);
