import { ACTIVITIES_INDEX, ACTIVITIES_CREATE, ACTIVITIES_UPDATE, ACTIVITIES_DELETE } from './types';

export const getActivities = () => ({
  type: ACTIVITIES_INDEX,
  request: {
    url: `/api/internal/v1/ras/activities`,
    method: 'get'
  },
  meta: {
    serialize: true
  }
});

export const createActivity = ({ activity }) => ({
  type: ACTIVITIES_CREATE,
  request: {
    url: `/api/internal/v1/ras/activities`,
    method: 'POST',
    data: { activity }
  }
});

export const updateActivity = ({ activity }) => ({
  type: ACTIVITIES_UPDATE,
  request: {
    url: `/api/internal/v1/ras/activities/${activity.id}`,
    method: 'PUT',
    data: { activity }
  }
});

export const deleteActivity = ({ id }) => ({
  type: ACTIVITIES_DELETE,
  request: {
    url: `/api/internal/v1/ras/activities/${id}`,
    method: 'delete'
  },
  meta: {
    id
  }
});
