import { SET_LANGUAGE } from "./types";

const INITIAL_STATE = navigator.language.split(/[-_]/)[0];

export default function reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SET_LANGUAGE: {
      return payload.language;
    }
    default:
      return state;
  }
}
