import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
import { parentActivities, dashboard, isLoading } from "../ducks/selectors";
import { startDashboardSaga } from "../ducks/actions";
import { bindActionCreators } from "redux";
import { userId, type } from "../../auth";
import Dashboard from "./Dashboard";
import { saveBuildingActivity, deleteBuildingActivity } from "../../building-activities";
import withAdministratorDetail from "../../shared/withAdministratorDetail";

const enhance = compose(
  withRouter,
  connect(
    state => ({
      buildings: dashboard(state),
      parentActivities: parentActivities(state),
      isLoading: isLoading(state),
      userId: userId(state),
      userType: type(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          startDashboardSaga,
          saveBuildingActivity,
          deleteBuildingActivity
        },
        dispatch
      )
    })
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props;

      const buildingQuery = {
        "q[with_not_all_ras_building_activities]": "yes",
        // "q[for_building_manager]": userId,
        "q[id_amm_eq]": match.params.id
      };

      actions.startDashboardSaga(buildingQuery);
    }
  }),
  withAdministratorDetail
);

export default enhance(Dashboard);
