import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { parentActivities, dashboard, isLoading } from "../ducks/selectors";
import { startDashboardSaga } from "../ducks/actions";
import { user, userId, type } from "../../auth";
import { bindActionCreators } from "redux";
import Dashboard from "./Dashboard";
import { saveBuildingActivity, deleteBuildingActivity } from "../../building-activities";
import { getManagers } from "../../managers"

const enhance = compose(
  connect(
    state => ({
      buildings: dashboard(state),
      parentActivities: parentActivities(state),
      isLoading: isLoading(state),
      userId: userId(state),
      userType: type(state),
      user: user(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          getManagers,
          startDashboardSaga,
          saveBuildingActivity,
          deleteBuildingActivity
        },
        dispatch
      )
    })
  ),
  lifecycle({
    componentDidMount() {
      const { actions, user } = this.props;

      const buildingQuery = {
        "q[with_not_all_ras_building_activities]": "yes"
      };

      actions.getManagers({ referId: user.referId, userType: user.userType });
      actions.startDashboardSaga(buildingQuery);
    }
  })
);

export default enhance(Dashboard);
