import React from "react";
import { Card, CardHeader, CardContent, Typography } from "@material-ui/core";

const AdministratorCard = ({ administrator }) => {
  return administrator ? (
    <Card>
      <CardHeader title={administrator.brand} subheader={<strong>{administrator.vat}</strong>} />
      <CardContent>
        <Typography variant="body1">{administrator.referent}</Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {`${administrator.address}, ${administrator.city} (${administrator.zip})`}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {administrator.email}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {administrator.phone}
        </Typography>
      </CardContent>
    </Card>
  ) : null;
};

export default AdministratorCard;
