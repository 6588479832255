import { requestsReducer, success } from "redux-saga-requests";
import { combineReducers } from "redux";
import { GET_TOKEN, LOGIN, LOGOUT } from "./types";

const INITIAL_STATE = { token: null };

const authentication = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case success(GET_TOKEN):
      return {
        ...state,
        token: action.data.token
      };
    case success(LOGIN):
      return {
        ...state,
        ...action.data
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default combineReducers({
  token: requestsReducer({ actionType: GET_TOKEN }),
  profile: requestsReducer({ actionType: LOGIN }),
  authentication
});
