import React from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { SentimentVeryDissatisfiedOutlined as SadIcon } from "@material-ui/icons";

import * as Messages from "./Messages";

export default function BuildingNotFoundDialog({ classes }) {
  const history = useHistory();

  const handleCloseDialog = () => {
    history.goBack();
  };

  return (
    <Dialog open={true} onClose={handleCloseDialog}>
      <DialogTitle>{Messages.BuildingNotFound}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialog}>
          <SadIcon fontSize="large" className={classes.largeIcon} />
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Chiudi</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
