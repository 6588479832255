import React from "react";
import Modal from "../../modal/Modal";
import { NotesList } from "../../notes";

const BuildingNoteDialog = ({ building, navigable, showFilters, ...props }) => (
  <Modal
    title={
      <>
        Note&nbsp;<small>{building.nome}</small>
      </>
    }
    {...props}
  >
    <NotesList
      showFilters={showFilters}
      navigable={navigable}
      filters={{ buildingId: String(building.id_edificio), buildingNotesOnly: true }}
    />
  </Modal>
);

export default BuildingNoteDialog;
