import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Username = <FormattedMessage id="managers.username" defaultMessage="Nome Utente" />;
export const FullName = (
  <FormattedMessage id="managers.full_name" defaultMessage="Nome &amp; Cognome" />
);
export const Email = <FormattedMessage id="managers.email" defaultMessage="Email" />;
export const BuildingAssociation = (
  <FormattedMessage id="managers.building_association" defaultMessage="Associazione Edifici" />
);

export const NewManager = (
  <FormattedMessage id="managers.new_manager" defaultMessage="Nuovo Responsabile" />
);
export const Add = <FormattedMessage id="managers.add" defaultMessage="Aggiungi" />;
export const Cancel = <FormattedMessage id="managers.cancel" defaultMessage="Annulla" />;
export const WrongEmail = (
  <FormattedMessage id="managers.wrong_email" defaultMessage="Email non valida" />
);
export const FieldRequired = (
  <FormattedMessage id="managers.field_required" defaultMessage="Campo obbligatorio" />
);
export const EditBuildingList = (
  <FormattedMessage id="managers.edit_building_list" defaultMessage="Modifica lista edifici" />
);
export const Managers = <FormattedMessage id="managers.managers" defaultMessage="Responsabili" />;
export const Manager = <FormattedMessage id="managers.manager" defaultMessage="Responsabile" />;
export const Associated = <FormattedMessage id="managers.associated" defaultMessage="Associato" />;
