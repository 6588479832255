import React from "react";
import { connect } from "react-redux";
import { Select, MenuItem, makeStyles } from "@material-ui/core";

import * as Messages from "./Messages";
import { buildings } from "../ducks/selectors";
import { compose } from "recompose";

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const BuildingsSelect = ({
  buildings,
  field,
  form,
  onSelectChange,
  submitOnChange,
  id,
  dispatch,
  ...other
}) => {
  const classes = useStyles();

  const handleChange = event => {
    form.setFieldValue(field.name, event.target.value, false);
    if (submitOnChange) setTimeout(form.submitForm, 0);
    if (onSelectChange) onSelectChange({ buildingId: event.target.value });
  };

  return (
    <Select
      className={classes.selectEmpty}
      {...field}
      {...other}
      displayEmpty
      onChange={handleChange}
    >
      <MenuItem key="none" value="">
        {Messages.None}
      </MenuItem>
      {buildings.map(({ buildingId, buildingName }) => (
        <MenuItem key={buildingId} value={buildingId}>
          {buildingName}
        </MenuItem>
      ))}
    </Select>
  );
};

const enhance = compose(connect(state => ({ buildings: buildings(state) })));

export default enhance(BuildingsSelect);
