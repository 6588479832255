import { AdministratorList } from "../administrators";
import { Login, Logout } from "../auth";
import { BuildingList, AdministratorBuildingList } from "../buildings";
import { BuildingDetail } from "../buildings";
import { ActivityList } from "../activities";
import { ManagerList } from "../managers";
import { Dashboard, AdministratorDashboard } from "../dashboard";
import { AddNotePage } from "../add-note";
import * as Features from "../features";
import NotesPage from "../notes/NotesPage";

const routes = [
  {
    path: "/administrators/:id/dashboard",
    component: AdministratorDashboard,
    isPrivate: true,
    feature: Features.canRouteAdministratorDashboard
  },
  {
    path: "/administrators/:id/buildings",
    component: AdministratorBuildingList,
    isPrivate: true,
    feature: Features.canRouteAdministratorDashboard
  },
  {
    path: "/dashboard",
    component: Dashboard,
    isPrivate: true,
    feature: Features.canRouteDashboard
  },
  {
    path: "/buildings/:id",
    component: BuildingDetail,
    isPrivate: true,
    feature: Features.canRouteBuilding
  },
  {
    path: "/buildings",
    component: BuildingList,
    isPrivate: true,
    feature: Features.canRouteBuildings
  },
  {
    path: "/administrators",
    component: AdministratorList,
    isPrivate: true,
    feature: Features.canRouteAdministrators
  },
  {
    path: "/activities",
    component: ActivityList,
    isPrivate: true,
    feature: Features.canRouteActivities
  },
  {
    path: "/managers",
    component: ManagerList,
    isPrivate: true,
    feature: Features.canRouteManagers
  },
  {
    path: "/add-note",
    component: AddNotePage,
    isPrivate: true,
    feature: Features.canAddNotes
  },
  {
    // TODO DA ELIMINARE
    path: "/notes",
    component: NotesPage,
    isPrivate: true
  },
  {
    path: "/login",
    component: Login,
    isPrivate: false
  },
  {
    path: "/logout",
    component: Logout,
    isPrivate: true
  }
];
export default routes;
