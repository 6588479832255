import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import ManagerList from "./ManagerList";
import { bindActionCreators } from "redux";
import { managers } from "../ducks/selectors";
import { getManagers } from "../ducks/actions";
import { type, referId } from "../../auth";

const enhance = compose(
  connect(
    state => ({
      managers: managers(state),
      userType: type(state),
      referId: referId(state)
    }),
    dispatch => bindActionCreators({ getManagers }, dispatch)
  ),
  lifecycle({
    componentDidMount() {
      const { getManagers, referId, userType } = this.props;
      getManagers({ referId, userType });
    }
  })
);

export default enhance(ManagerList);
