import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const It = (
  <FormattedMessage
    id="shared.language_selector.it"
    defaultMessage="Italiano"
  />
);
export const En = (
  <FormattedMessage id="shared.language_selector.en" defaultMessage="Inglese" />
);
