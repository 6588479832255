import { createSelector } from "reselect";
import { activitiesMap } from "../../activities/ducks/selectors";
import { flatten, isEmpty, sortBy } from "lodash";
import moment from "moment";

const getSlice = state => state.notes;

// Prendo sempre il primo evento, non ce ne dovrebbero essere altri in risposta dall'api
// oppure è vuoto, quindi ritorno oggetto vuoto per non farlo schiantare
//TODO: remove this selector
export const rasEvent = createSelector(
  getSlice,
  slice =>
    (slice.notes.data !== null && slice.notes.data.event && slice.notes.data.event[0]) || {
      notes: []
    }
);

export const notesSlice = createSelector(getSlice, slice => slice.notes.data);

//TODO: remove this selector
export const rasEventId = createSelector(rasEvent, ({ id }) => {
  return id;
});

export const buildingFiscalCode = state => state.buildingDetail.buildingDetail.codfisc;

export const groupedNotes = createSelector(rasEvent, activitiesMap, ({ notes }, activities) =>
  notes.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.activityIdRas]: {
        activity: isEmpty(curr.activityIdRas) ? "building" : activities[curr.activityIdRas],
        notes: [...((prev[curr.activityIdRas] || {}).notes || []), curr]
      }
    }),
    {}
  )
);

export const allNotes = filters =>
  createSelector(notesSlice, activitiesMap, (events, activities) => {
    return sortBy(
      flatten(
        Object.values(events)
          .filter(x => !Boolean(filters.buildingId) || x.buildingId === filters.buildingId)
          .map(({ buildingId, buildingName, notes }) =>
            notes.map(note => ({ ...note, buildingName, buildingId }))
          )
      )
        .filter(x => !Boolean(filters.activityId) || x.activityIdRas === filters.activityId)
        .filter(
          x =>
            !Boolean(filters.buildingNotesOnly) ||
            (filters.buildingNotesOnly && isEmpty(x.activityIdRas))
        )
        .map(note => {
          return {
            ...note,
            activity: isEmpty(note.activityIdRas) ? {} : activities[note.activityIdRas]
          };
        }),
      note => -moment(note.creationDate)
    );
  });

export const loadingNotes = createSelector(getSlice, slice => slice.notes.loading);
