import React from "react";
import { Drawer, Box, makeStyles, Hidden } from "@material-ui/core";
import NotesList from "./NotesListContainer";

const notesWellDrawerWidth = 420;

const useStyles = makeStyles(theme => ({
  appBarSpacer: theme.mixins.toolbar,
  menuDrawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: notesWellDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}));

const NotesWell = () => {
  const classes = useStyles();
  return (
    <Hidden mdDown>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.menuDrawerPaper
        }}
        anchor="right"
      >
        <div className={classes.appBarSpacer} />
        <Box margin={2}>
          <NotesList showFilters navigable listMode />
        </Box>
      </Drawer>
    </Hidden>
  );
};

export default NotesWell;
