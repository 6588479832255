import React from "react";
import { render } from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { createMuiTheme } from "@material-ui/core";
import { blue, pink } from "@material-ui/core/colors";
import configureStore from "./store";

import App from "./App";

const DOM_MOUNT_NODE = document.getElementById("root");
const store = configureStore({
  auth: {
    authentication: {
      ...(sessionStorage.getItem("user") && JSON.parse(sessionStorage.getItem("user"))),
      token: sessionStorage.getItem("authToken")
    }
  }
});

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: blue,
    secondary: pink
  }
});

export const renderApp = htmlElement =>
  render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router>
            <App />
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>,
    htmlElement
  );

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept(["./i18n", "./App"], () => renderApp(DOM_MOUNT_NODE));
}

renderApp(DOM_MOUNT_NODE);
