import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import * as Messages from "./Messages";

export default () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {Messages.Copyright}
    <Link color="inherit" href="https://material-ui.com/">
      {Messages.Brand}
    </Link>{" "}
    {new Date().getFullYear()}
  </Typography>
);
