import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { getBuildings } from "../../buildings/ducks/actions";
import { getBuildingActivities } from "./../../building-activities";
import NotesFilterComponent from "./NotesFilterComponent";

const enhance = compose(
  connect(undefined, dispatch => ({
    actions: bindActionCreators(
      {
        getBuildings,
        getBuildingActivities
      },
      dispatch
    )
  })),
  lifecycle({
    componentDidMount() {
      const { buildingId, actions } = this.props;

      actions.getBuildings();
      if (buildingId) {
        actions.getBuildingActivities();
      }
    }
  }),
  withHandlers({
    onBuildingChange: ({ actions }) => ({ buildingId }) => {
      if (buildingId) {
        actions.getBuildingActivities();
      }
    }
  })
);

export default enhance(NotesFilterComponent);
