import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Paper,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as Messages from "./Messages";
import { userId } from "../../auth";

const useStyles = makeStyles(theme => ({
  filtersPaper: {
    padding: theme.spacing(2)
  }
}));

const DashboardFilters = ({ onFilterChange, userId, managers, query, administratorId }) => {

  const classes = useStyles();
  const [scopeFilter, setScopeFilter] = useState("Ko");
  const [scopeUserFilter, setScopeUserFilter] = useState("All");
  const [buildingVisibilityFilter, setBuildingVisibilityFilter] = useState(false);

  const handleScopeFilterChange = evt => {
    const scope = evt.target.value;
    setScopeFilter(scope);

    let scopeQuery = {};
    switch (scope) {
      case "Ok":
        scopeQuery["q[with_all_ras_building_activities]"] = "yes";
        scopeQuery["q[with_not_all_ras_building_activities]"] = null;
        break;
      case "Ko":
        scopeQuery["q[with_all_ras_building_activities]"] = null;
        scopeQuery["q[with_not_all_ras_building_activities]"] = "yes";
        break;
      default:
        scopeQuery["q[with_all_ras_building_activities]"] = null;
        scopeQuery["q[with_not_all_ras_building_activities]"] = null;
        break;
    }
    onFilterChange({ ...query, ...scopeQuery });
  };

  const handleBuildingVisibilityFilterChange = evt => {

    if(evt.target.checked) setScopeUserFilter("All");

    setBuildingVisibilityFilter(evt.target.checked);
    const buildingVisibilityQuery = {
      "q[for_building_manager]": evt.target.checked ? userId : null
    };
    onFilterChange({ ...query, ...buildingVisibilityQuery });
  };

  const handleScopeUserFilterChange = evt => {

    setBuildingVisibilityFilter(false);

    const scope = evt.target.value;
    setScopeUserFilter(scope);

    const scopeQuery = {
      "q[for_building_manager]": scope == "All" ? null : scope
    };
    onFilterChange({ ...query, ...scopeQuery });
  };

// (((managers || {}).data || {}).users || [])

  return (
    <Paper className={classes.filtersPaper}>
      <Grid container spacing={4}>
        <Grid item>
          <FormControl>
            <Select value={scopeFilter} onChange={handleScopeFilterChange}>
              <MenuItem value="All">{Messages.All}</MenuItem>
              <MenuItem value="Ko">{Messages.Issues}</MenuItem>
              <MenuItem value="Ok">{Messages.Ok}</MenuItem>
            </Select>
            <FormHelperText>{Messages.FilterSubtext}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <Select value={scopeUserFilter} onChange={handleScopeUserFilterChange}>
              <MenuItem value="All">{Messages.All}</MenuItem>
              {
                (((managers || {}).data || {}).users || []).map((user, index) => {
                  return <MenuItem key={index} value={user.userId}>{`${user.name} - ${user.username}`}</MenuItem>
                })
              }
            </Select>
            <FormHelperText>{Messages.UserFilterSubtext}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={buildingVisibilityFilter}
                  onChange={handleBuildingVisibilityFilterChange}
                />
              }
              labelPlacement="end"
              label={Messages.FilterBuildingVisibility}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default connect(state => ({ userId: userId(state), managers: state.managers.list }))(DashboardFilters);
