import React from "react";
import Modal from "../../modal/Modal";
import * as Messages from "./Messages";
import ManagerAssignmentTable from "./ManagerAssignmentTable";

const ManagerAssignmentDialog = ({ building, ...props }) => (
  <Modal
    title={
      <>
        {Messages.Managers}&nbsp;<small>{building.nome}</small>
      </>
    }
    {...props}
  >
    <ManagerAssignmentTable buildingId={building.buildingId} />
  </Modal>
);

export default ManagerAssignmentDialog;
