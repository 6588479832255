import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { isAuthenticated } from "../ducks/selectors";

export default connect(state => ({
  isAuthenticated: isAuthenticated(state)
}))(({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    component={props =>
      !!isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
));
