import { takeEvery } from "redux-saga/effects";
import { LOGIN, GET_TOKEN, LOGOUT } from "./types";
import { success } from "redux-saga-requests";

export function saveToken({ data: { token } }) {
  sessionStorage.setItem("authToken", token);
}

export function removeTokenAndUser() {
  sessionStorage.removeItem("authToken");
  sessionStorage.removeItem("user");
}

export function saveProfile(action) {
  sessionStorage.setItem("user", JSON.stringify(action.data));
}

export default function* authSaga() {
  yield takeEvery(success(LOGIN), saveProfile);
  yield takeEvery(success(GET_TOKEN), saveToken);
  yield takeEvery(LOGOUT, removeTokenAndUser);
}
