import {
  SET_FILTERS,
  GET_BUILDINGS,
  GET_BUILDING,
  START_GET_BUILDING_SAGA,
  END_GET_BUILDING_SAGA,
  CREATE_BUILDING_ACTIVITY,
  TOGGLE_ASSIGNED_STATE
} from "./types";

export const setFilters = newFilters => ({
  type: SET_FILTERS,
  newFilters
});

export const getBuildings = query => {
  return {
    type: GET_BUILDINGS,
    request: {
      url: `/api/internal/v1/ras/buildings`,
      method: "GET",
      params: query,
      headers: {
        USERIDLOGGED: JSON.parse(sessionStorage.getItem("user")).userId
      }
    }
  };
};

export const getBuilding = buildingId => ({
  type: GET_BUILDING,
  request: {
    url: `/api/internal/v1/ras/buildings/${buildingId}`,
    method: "get"
  },
  meta: {
    serialize: {
      ras_activity: {
        valueForRelationship: relationship => ({
          id: relationship.id
        })
      },
      building: {
        valueForRelationship: relationship => ({
          id: relationship.id
        })
      }
    }
  }
});

export const startGetBuildingSaga = ({ buildingId }) => ({
  type: START_GET_BUILDING_SAGA,
  buildingId
});

export const endGetBuildingSaga = ({ building, activities }) => ({
  type: END_GET_BUILDING_SAGA,
  building,
  activities
});

export const createBuildingActivities = ({ buildingId, activityId }) => ({
  type: CREATE_BUILDING_ACTIVITY,
  request: {
    url: `/api/internal/v1/ras/building_activities`,
    method: "POST",
    data: {
      building_activity: {
        building_id_edificio: buildingId,
        ras_activity_id: activityId
      }
    }
  },
  meta: {
    serialize: {
      ras_activity: {
        valueForRelationship: ({ id }) => ({ id })
      }
    }
  }
});

export const toggleAssignedState = ({ buildingId }) => ({
  type: TOGGLE_ASSIGNED_STATE,
  request: {
    url: `/api/internal/v1/ras/buildings/${buildingId}/toggle_assigned_state`,
    method: "PATCH"
  },
  meta: {
    serialize: true
  }
});
