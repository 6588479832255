import { createSelector } from "reselect";
import { reduce } from "lodash";

const getSlice = state => state.activities;

export const activitiesMap = createSelector(getSlice, slice => {
  return reduce(
    slice.list.data || [],
    (acc, { id, ...rest }) => {
      return { ...acc, [id]: { id, ...rest } };
    },
    {}
  );
});

export const allActivities = createSelector(activitiesMap, activities => Object.values(activities));

export const parentActivities = createSelector(allActivities, activities =>
  activities.filter(x => x.parent_id === null)
);
