import { combineReducers } from "redux";
import language from "./i18n/ducks/reducer";
import auth from "./auth/ducks/reducer";
import { reducer as administrators } from "./administrators";
import { reducer as buildings } from "./buildings";
import { reducer as dashboard } from "./dashboard";
import { reducer as activities } from "./activities";
import { reducer as notifications } from "./notifications";
import { reducer as notes } from "./notes";
import { reducer as buildingActivities } from "./building-activities";
import { reducer as managers } from "./managers";

export default combineReducers({
  language,
  auth,
  buildings,
  administrators,
  activities,
  dashboard,
  notifications,
  buildingActivities,
  notes,
  managers
});
