import { applyMiddleware, compose, createStore } from "redux";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { requestsPromiseMiddleware } from "redux-saga-requests";
import rootReducer from "./reducers";
import rootSaga from "./rootSaga";

export default function configureStore(preloadedState) {
  const sagaMiddleware = createSagaMiddleware();
  const promiseMiddleware = requestsPromiseMiddleware({ auto: true });

  const middlewares = [sagaMiddleware, promiseMiddleware];
  if (process.env.NODE_ENV !== "production") {
    middlewares.push(logger);
  }
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  sagaMiddleware.run(rootSaga);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }
  return store;
}
