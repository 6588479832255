import { put, takeLatest, take, select } from "redux-saga/effects";
import { isEmpty } from "lodash";
import { getActivities, ACTIVITIES_INDEX, activitiesMap } from "../../activities";
import { SET_FILTERS, START_GET_BUILDING_SAGA, GET_BUILDING } from "./types";
import { filters } from './selectors';
import { success } from "redux-saga-requests";
import { getBuilding, startGetBuildingSaga, endGetBuildingSaga, getBuildings } from "./actions";

function* handleSaga({ buildingId }) {
  const activities = yield select(activitiesMap);
  if (isEmpty(activities)) {
    yield put(getActivities());
    yield take(success(ACTIVITIES_INDEX));
    yield put(startGetBuildingSaga({ buildingId }));
  }
  yield put(getBuilding(buildingId));
  const { data: building } = yield take(success(GET_BUILDING));
  yield put(endGetBuildingSaga({ building, activities }));
}

function* handleSetFilters(action) {
  const query = yield select(filters);
  yield put(getBuildings(query));
}

export default function* buildingsSaga() {
  yield takeLatest(START_GET_BUILDING_SAGA, handleSaga);
  yield takeLatest(SET_FILTERS, handleSetFilters);
}
