import React, { useState } from "react";
import { map } from "lodash";
import { compose, withHandlers, withState } from "recompose";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Checkbox,
  TextField,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  Divider,
  Grid as MUIGrid
} from "@material-ui/core";
import { Add, EditOutlined, DeleteOutlined, SaveOutlined, CancelOutlined, Done, Close } from "@material-ui/icons";
import { EditingState, DataTypeProvider } from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableEditRow,
  TableHeaderRow,
  TableEditColumn
} from "@devexpress/dx-react-grid-material-ui";
import TableComponentBase from "../../shared/TableComponentBase";
import {
  createCorrectiveAction,
  updateCorrectiveAction,
  deleteCorrectiveAction
} from "./../ducks/actions";
import * as Messages from "./Messages";

const redIcon = {
  color: "red"
};

const greenIcon = {
  color: "green"
};

const columns = [
  {
    name: "done",
    title: Messages.CorrectiveActionDone
  },
  {
    name: "verified",
    title: Messages.CorrectiveActionVerified
  },
  {
    name: "body",
    title: Messages.Description
  }
];

const useStyles = makeStyles(theme => ({
  // title: {
  //   marginRight: 4
  // },
  // titleContainer: {
  //   marginBottom: 4
  // }
}));

const useCellStyles = makeStyles(theme => ({
  cell: { maxHeight: "160px", display: "block", overflowY: "auto" }
}));

const VerifiedBooleanFormatter = ({ row }) =>
  row.verified ? <Done style={greenIcon} /> : <Close style={redIcon} />;

const DoneBooleanFormatter = ({ row }) =>
  row.done ? <Done style={greenIcon} /> : <Close style={redIcon} />;

const CustomCell = ({ value, column, ...cellProps }) => {
  const classes = useCellStyles();
  return column.name === "body" ? (
    <Table.Cell style={{ whiteSpace: "normal" }}>
      <Typography
        variant="caption"
        dangerouslySetInnerHTML={{ __html: value.replace(/\n/g, "<br/>") }}
        className={classes.cell}
      ></Typography>
    </Table.Cell>
  ) : (
    <Table.Cell value={value} column={column} {...cellProps} />
  );
};

const EditCell = ({ defaultCorrectiveAction }) => ({ value, row, ...cellProps }) => {

  switch (cellProps.column.name) {
    case "done":
      return (
        <Table.Cell {...cellProps}>
          <Checkbox
            value={value}
            checked={value}
            onChange={event => cellProps.onValueChange(event.target.checked)}
          />
        </Table.Cell>
      );
    case "verified":
      return (
        <Table.Cell {...cellProps}>
          <Checkbox
            value={value}
            checked={value}
            onChange={event => cellProps.onValueChange(event.target.checked)}
          />
        </Table.Cell>
      );
    default:
      return (
        <Table.Cell {...cellProps}>
          <TextField
            fullWidth={true}
            multiline
            rows="4"
            defaultValue={value || defaultCorrectiveAction}
            variant="outlined"
            onChange={event => cellProps.onValueChange(event.target.value)}
          />
        </Table.Cell>
      );
  }
};

const commandIconMap = {
  add: { icon: <Add />, label: Messages.Add },
  edit: { icon: <EditOutlined />, label: Messages.Edit },
  delete: { icon: <DeleteOutlined />, label: Messages.Delete },
  commit: { icon: <SaveOutlined />, label: Messages.Save },
  cancel: { icon: <CancelOutlined />, label: Messages.Cancel }
};

const CommandButton = ({ ...commandProps }) => (
  <Tooltip placement="top" title={commandIconMap[commandProps.id].label}>
    <IconButton size="small" onClick={commandProps.onExecute}>
      {commandIconMap[commandProps.id].icon}
    </IconButton>
  </Tooltip>
);

/*
const handleAddCorrectiveAction = ({ addedRows, setAddedRows }) => () => {
  addedRows.push({ verified: false, body: "" });
  setAddedRows(addedRows);
};
*/

// const handleCancelCorrectiveActions = ({ setAddedRows }) => () => setAddedRows([]);

const CorrectiveActionsTable = ({
  correctiveActions,
  defaultCorrectiveAction,
  onCorrectiveActionsChange,
  // addedRows,
  // setAddedRows
}) => {
  const [columnExtensions] = useState([
    {
      columnName: "body",
      width: "auto"
    },
    {
      columnName: "done",
      width: "15%",
      align: "center"
    },
    {
      columnName: "verified",
      width: "15%",
      align: "center"
    }
  ]);
  const classes = useStyles();
  return (
    <>
      <MUIGrid className={classes.titleContainer} container alignItems="center">
        <MUIGrid item>
          <Typography className={classes.title} variant="body1">
            <strong>{Messages.CorrectiveAction}</strong>
          </Typography>
        </MUIGrid>
        {/*
        <MUIGrid item>
          <Tooltip placement="top" title={commandIconMap.add.label}>
            <IconButton onClick={handleAddCorrectiveAction({ addedRows, setAddedRows })} size="small">
              {commandIconMap.add.icon}
            </IconButton>
          </Tooltip>
        </MUIGrid>
        */}
      </MUIGrid>
      <Divider />
      <Grid rows={correctiveActions} columns={columns} getRowId={row => row.id}>
        <EditingState
          //addedRows={addedRows}
          //onAddedRowsChange={handleCancelCorrectiveActions({ setAddedRows })}
          onCommitChanges={onCorrectiveActionsChange}
        />
        <DataTypeProvider formatterComponent={DoneBooleanFormatter} for={["done"]} />
        <DataTypeProvider formatterComponent={VerifiedBooleanFormatter} for={["verified"]} />
        <Table
          tableComponent={TableComponentBase}
          columnExtensions={columnExtensions}
          cellComponent={CustomCell}
        />
        <TableHeaderRow />
        <TableEditRow cellComponent={EditCell({ defaultCorrectiveAction })} />
        <TableEditColumn
          showAddCommand
          showEditCommand
          showDeleteCommand
          width="10%"
          commandComponent={CommandButton}
        />
      </Grid>
    </>
  );
};

export default compose(
  connect(undefined, dispatch => ({
    actions: bindActionCreators(
      { createCorrectiveAction, updateCorrectiveAction, deleteCorrectiveAction },
      dispatch
    )
  })),
  withState("addedRows", "setAddedRows", []),
  withHandlers({
    onCorrectiveActionsChange: ({
      actions,
      onRefresh,
      buildingActivityId,
      defaultCorrectiveAction
    }) => ({ added, changed, deleted }) => {
      map(added, correctiveAction =>
        actions
          .createCorrectiveAction({
            buildingActivityId,
            correctiveAction: {
              done: correctiveAction.done,
              verified: correctiveAction.verified,
              body: correctiveAction.body || defaultCorrectiveAction
            }
          })
          .then(() => onRefresh && onRefresh())
      );
      map(changed, (correctiveAction, correctiveActionId) =>
        actions
          .updateCorrectiveAction({
            correctiveActionId,
            correctiveAction
          })
          .then(() => onRefresh && onRefresh())
      );
      map(deleted, correctiveActionId =>
        actions.deleteCorrectiveAction({ correctiveActionId }).then(() => onRefresh && onRefresh())
      );
    }
  })
)(CorrectiveActionsTable);
