import { GET_TOKEN, LOGIN, LOGOUT } from "./types";

export const getToken = () => ({
  type: GET_TOKEN,
  request: {
    url: "/copernico_crm_api/GetToken.php",
    key: "get_token",
    headers: {
      CLIENTID: "K6ezYiZPamm9IgtK1y9jeFq7HJmNRM7W",
      CLIENTSECRET: "_nr6Sc9a9KcfJ0DTCOTNNS_CqTsERnl1l0F51yUsylmm4CrStG96jW9hnvNuE7gU"
    }
  }
});

export const login = values => ({
  type: LOGIN,
  request: {
    url: "/copernico_crm_api/Users/Login/Login.php",
    auth: {
      username: values.email,
      password: values.password
    },
    headers: {
      TOKEN: sessionStorage.getItem("authToken")
    }
  }
});

export const logout = () => ({
  type: LOGOUT
});
