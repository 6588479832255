import { combineReducers } from "redux";
import { requestsReducer, error } from "redux-saga-requests";
import {
  SET_FILTERS,
  GET_BUILDINGS,
  END_GET_BUILDING_SAGA,
  GET_BUILDING,
  START_GET_BUILDING_SAGA
} from "./types";
import _ from "lodash";

const INITIAL_STATE = {
  filters: {},
  loading: false,
  error: false
};

const detailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        filters: action.newFilters
      };
    case START_GET_BUILDING_SAGA: {
      return { ...state, loading: true, error: false };
    }
    case END_GET_BUILDING_SAGA:
      const { building, activities } = action;
      const {
        id_edificio,
        codfisc,
        nome,
        indirizzo,
        citta,
        provincia,
        ras_building_activities,
        assigned_state
      } = building;

      const buildingActivities = ras_building_activities.map(ba => ({
        ...ba,
        activity: activities[ba.ras_activity.id]
      }));

      const missingActivities = _.reject(activities, x =>
        _.find(buildingActivities, ["ras_activity.id", x.id])
      );

      return {
        ...state,
        loading: false,
        error: false,
        building: { id_edificio, codfisc, nome, indirizzo, citta, provincia, assigned_state },
        buildingActivities,
        missingActivities:
          missingActivities.map(ma => activities[ma.id]).filter(x => x.parent_id === null) || []
      };
    case error(GET_BUILDING): {
      return { loading: false, error: true };
    }
    default:
      return state;
  }
};

export default combineReducers({
  list: requestsReducer({ actionType: GET_BUILDINGS }),
  detail: detailReducer
});
