import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import BuildingListComponent from "./BuildingListComponent";
import { getBuildings, buildings } from "..";

const enhance = compose(
  connect(
    state => ({
      buildings: buildings(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          getBuildings
        },
        dispatch
      )
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.actions.getBuildings();
    }
  })
  // withLoader({ loaderkey: BUILDING_INDEX_KEY })
);

export default enhance(BuildingListComponent);
