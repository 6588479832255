import React from 'react';
import { FormattedMessage } from 'react-intl.macro';

export const Administrators = (
  <FormattedMessage id="administrators_list.administrators" defaultMessage="Amministratori" />
);
export const Brand = <FormattedMessage id="administrator_list.id" defaultMessage="Brand" />;
export const Name = <FormattedMessage id="administrator_list.name" defaultMessage="Nome" />;
export const Referent = <FormattedMessage id="administrator_list.referent" defaultMessage="Referente" />;
export const City = <FormattedMessage id="administrator_list.city" defaultMessage="Città" />;
export const Phone = <FormattedMessage id="administrator_list.phone" defaultMessage="Telefono" />;
export const Email = <FormattedMessage id="administrator_list.email" defaultMessage="Email" />;
export const Address = <FormattedMessage id="administrator_list.address" defaultMessage="Indirizzo" />;
export const Status = <FormattedMessage id="administrator_list.status" defaultMessage="Tipo account" />;
export const FiscalCode = <FormattedMessage id="administrator_list.fiscal_code" defaultMessage="Codice Fiscale" />;
export const OpenAdministratorDashboard = <FormattedMessage id="administrator_list.open_administrator_dashboard" defaultMessage="Apri dashboard amministratore" />;
export const OpenAdministratorBuildings = <FormattedMessage id="administrator_list.open_administrator_buildings" defaultMessage="Apri edifici amministratore" />;
