import { createSelector } from "reselect";
import { getQuery } from "redux-saga-requests";
import { keyBy, map, includes, filter } from "lodash";
import { GET_MANAGERS, GET_MANAGER_BUILDINGS } from "./types";
import { buildingsQuery } from "../../buildings/ducks/selectors";

const managersQuery = getQuery({
  type: GET_MANAGERS,
  multiple: true,
  requestSelector: state => state.managers.list
});

const managerBuildingsQuery = getQuery({
  type: GET_MANAGER_BUILDINGS,
  multiple: true,
  requestSelector: state => state.managers.buildings
});

export const managers = createSelector(managersQuery, query => query.data.users || []);

export const buildingAssociations = createSelector(
  buildingsQuery,
  managerBuildingsQuery,
  (q1, q2) => {
    if (q1.loading || q2.loading) return undefined;

    const f = keyBy(q2.data, "building.id");

    // TODO le api di copernico mi ritornano un object e va in conflitto con redux-saga-requests
    const result = (q1.data.buildings || []).map(x => {
      return {
        buildingId: x.buildingId,
        buildingName: x.buildingName,
        selected: !!f[x.buildingId] || false,
        buildingManagerId: f[x.buildingId] && f[x.buildingId].id
      };
    });
    return result;
  }
);

export const associatedManagers = createSelector(
  managersQuery,
  managerBuildingsQuery,
  ({ data }, { data: associatedManagers }) => {
    const allManagerIds = map(data.users, ({ userId }) => userId);
    const managers = map(
      filter(associatedManagers, ({ manager }) =>
        includes(allManagerIds, manager.user_id.toString())
      ),
      ({ manager, building }) => {
        return { ...manager, associatedBuildingId: building.id };
      }
    );
    return managers;
  }
);

export const managerAssociations = createSelector(
  managersQuery,
  managerBuildingsQuery,
  (q1, q2) => {
    if (q1.loading || q2.loading) return undefined;

    const f = keyBy(q2.data, "manager.user_id");

    // TODO le api di copernico mi ritornano un object e va in conflitto con redux-saga-requests
    const result = (q1.data.users || []).map(x => {
      return {
        userId: x.userId,
        name: x.name,
        email: x.email,
        selected: !!f[x.userId] || false,
        buildingManagerId: f[x.userId] && f[x.userId].id
      };
    });
    return result;
  }
);
