import React from "react";
import { Grid, FormControl, InputLabel, FormControlLabel, Checkbox } from "@material-ui/core";
import { Formik, Field } from "formik";
import * as Messages from "./Messages";
import { BuildingsSelect } from "../../buildings";
import { BuildingActivitiesSelect } from "../../building-activities";

const NotesFilter = ({ onFilterChange, onBuildingChange, filters }) => {
  const initialValues = {
    ...{
      buildingId: "",
      activityId: "",
      buildingNotesOnly: Boolean(false)
    },
    ...filters
  };

  return (
    <Formik onSubmit={onFilterChange} initialValues={initialValues}>
      {({ handleChange, handleSubmit, setFieldValue, values }) => {
        const handleBuildingSelectChange = value => {
          onBuildingChange(value);
          setFieldValue("activityId", "");
        };
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} xl={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel shrink>{Messages.BuildingSelect}</InputLabel>
                <Field
                  submitOnChange
                  onSelectChange={handleBuildingSelectChange}
                  component={BuildingsSelect}
                  name="buildingId"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} xl={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel shrink>{Messages.BuildingActivitySelect}</InputLabel>
                <Field
                  submitOnChange
                  buildingId={values.buildingId}
                  component={BuildingActivitiesSelect}
                  name="activityId"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isRead}
                    onChange={e => {
                      handleChange(e);
                      setTimeout(handleSubmit, 0);
                    }}
                    color="primary"
                    name="isRead"
                  />
                }
                label={Messages.UnreadNotesOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.buildingNotesOnly}
                    onChange={e => {
                      handleChange(e);
                      setTimeout(handleSubmit, 0);
                    }}
                    color="primary"
                    name="buildingNotesOnly"
                  />
                }
                label={Messages.BuildingNotesOnly}
              />
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default NotesFilter;
