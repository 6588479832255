import { success, error, abort } from "redux-saga-requests";
import { GET_MANAGER_BUILDINGS } from "./types";

const initialState = {
  data: null,
  fetching: false,
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGER_BUILDINGS:
      return { ...state, fetching: true };
    case success(GET_MANAGER_BUILDINGS):
      return {
        ...initialState,
        data: { ...action.data }
      };
    case error(GET_MANAGER_BUILDINGS):
      return { ...initialState, error: true };
    case abort(GET_MANAGER_BUILDINGS):
      return { ...initialState, fetching: false };
    default:
      return state;
  }
};
