import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { LoginForm } from "./LoginForm";
import Copyright from "./Copyright";
import * as Messages from "./Messages";
import { LanguageSelector } from "../../i18n";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url(/login.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  lang: {
    position: "absolute",
    right: 32
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default ({ isAuthenticated, rememberMe, forgotPassword, signUp, authActions }) => {
  const classes = useStyles();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />

      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <LanguageSelector className={classes.lang} />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {Messages.Title}
          </Typography>
          <Formik initialValues={{ email: "", password: "" }} onSubmit={authActions.login}>
            {props => <LoginForm rememberMe={rememberMe} classes={classes} {...props} />}
          </Formik>
          <Grid container>
            {forgotPassword && (
              <Grid item xs>
                <Link href="#" variant="body2">
                  {Messages.ForgotPassword}
                </Link>
              </Grid>
            )}
            <Grid item>
              {signUp && (
                <Link href="#" variant="body2">
                  {Messages.SignUpHelper}
                </Link>
              )}
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};
