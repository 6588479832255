import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { forEach } from "lodash";
import { Paper, Radio } from "@material-ui/core";
import { Grid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import * as Messages from "./Messages";
import { getBuilding } from "../../buildings";
import { user } from "../../auth";
import { managerAssociations } from "../ducks/selectors";
import { getManagers, getBuildingManagers, associate, disassociate } from "../ducks/actions";

const ManagerAssociationTypeProvider = ({ onAssociationChange, managerAssociations, ...props }) => {
  return (
    <DataTypeProvider
      {...props}
      formatterComponent={({ value, row }) => (
        <Radio
          checked={value}
          onChange={evt => {
            onAssociationChange({
              userId: row.userId,
              selected: evt.target.checked,
              managerAssociations
            });
          }}
        />
      )}
    />
  );
};

const ManagerAssignmentTable = ({ onAssociationChange, managerAssociations = [] }) => {
  const [columns] = useState([
    {
      name: "selected",
      title: Messages.Associated
    },
    {
      name: "name",
      title: Messages.Manager
    },
    {
      name: "email",
      title: Messages.Manager
    }
  ]);
  return (
    <Paper>
      <Grid rows={managerAssociations} columns={columns}>
        <ManagerAssociationTypeProvider
          for={["selected"]}
          managerAssociations={managerAssociations}
          onAssociationChange={onAssociationChange}
        />
        <Table />
        <TableHeaderRow />
      </Grid>
    </Paper>
  );
};

const enhance = compose(
  connect(
    state => ({
      managerAssociations: managerAssociations(state),
      user: user(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          getManagers,
          getBuilding,
          getBuildingManagers,
          associate,
          disassociate
        },
        dispatch
      )
    })
  ),
  lifecycle({
    async componentDidMount() {
      const { actions, user, buildingId } = this.props;
      await actions.getManagers({ referId: user.referId, userType: user.userType });
      actions.getBuildingManagers({ buildingId });
    }
  }),
  withHandlers({
    onAssociationChange: ({ buildingId, actions }) => async ({
      managerAssociations,
      userId,
      selected
    }) => {
      forEach(
        managerAssociations,
        async ({ buildingManagerId }) =>
          buildingManagerId && (await actions.disassociate({ buildingManagerId }))
      );
      await actions.associate({ buildingId, userId });
      actions.getBuildingManagers({ buildingId });
      // let promise;
      // if (selected) {
      //   promise = actions.associate({ buildingId, userId });
      // } else {
      //   promise = actions.disassociate({ buildingManagerId });
      // }
      // promise.then(() => getBuildingManagers({ buildingId }));
    }
  })
);

export default enhance(ManagerAssignmentTable);
