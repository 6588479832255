import { connect } from "react-redux";
import { compose } from "recompose";

import { allNotes } from "../ducks/selectors";
import NotesTable from "./NotesTableComponent";

export const enhance = compose(
  connect((state, { filters }) => ({
    notes: allNotes(filters)(state)
  }))
);

export default enhance(NotesTable);
