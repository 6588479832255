import { START_DASHBOARD_SAGA, END_DASHBOARD_SAGA } from "./types";

export const startDashboardSaga = filters => ({
  type: START_DASHBOARD_SAGA,
  filters
});
export const endDashboardSaga = ({ buildings, activities }) => ({
  type: END_DASHBOARD_SAGA,
  buildings,
  activities
});
