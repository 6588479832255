import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Launch } from "@material-ui/icons";
import { chain, isEmpty } from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Avatar,
  LinearProgress,
  Grid
} from "@material-ui/core";
import { useState } from "react";
import NoteDialog from "./NoteDialog";

const useStyles = makeStyles(theme => ({
  // root: {
  //   width: '100%',
  //   maxWidth: 360,
  //   backgroundColor: theme.palette.background.paper,
  // },
  noteText: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

const creationDate = note => {
  const creationDate = moment(note.creationDate);
  const format =
    moment().diff(creationDate, "days") === 0
      ? "HH:mm"
      : moment().year() === creationDate.year()
      ? "DD MMM"
      : "DD/MM/YY";
  return creationDate.format(format);
};

const senderName = note => {
  return chain([note.senderOffice, note.senderName])
    .compact()
    .join(" - ")
    .value();
};

const buildingAndImplantName = note => {
  const names = [];
  names.push(note.buildingName);
  if (!isEmpty(note.activity)) names.push(note.activity.title);

  return names.join(" - ");
};

const link = note => {
  let toUrl = [];
  if (!isEmpty(note.buildingId)) toUrl.push(`/buildings/${note.buildingId}`);
  if (!isEmpty(note.activity)) toUrl.push(`?activityId=${note.activity.id}`);

  return (
    <IconButton edge="end" component={Link} to={toUrl.join("")}>
      <Launch />
    </IconButton>
  );
};

const NotesListItemsComponent = ({ notes, loading }) => {
  const classes = useStyles();

  const [note, setNote] = useState(null);

  const handleCloseDialog = () => {
    setNote(null);
  };

  const handleOpenDialog = note => {
    setNote(note);
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <>
      <List>
        {notes.map((note, index) => {
          return (
            <ListItem
              key={index}
              alignItems="flex-start"
              button
              onClick={() => handleOpenDialog(note)}
            >
              <ListItemAvatar>
                <Avatar alt={note.senderName} src={note.senderPhoto} />
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={`${creationDate(note)} - ${senderName(note)}`}
                secondary={
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="caption" color="textPrimary">
                        {buildingAndImplantName(note)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.noteText}>{note.description}</Typography>
                    </Grid>
                  </Grid>
                }
              />
              <ListItemSecondaryAction>{link(note)}</ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      {note && <NoteDialog note={note} onClose={handleCloseDialog} />}
    </>
  );
};

export default NotesListItemsComponent;
