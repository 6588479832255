import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { FeatureToggles, Feature } from "@paralleldrive/react-feature-toggles";
import { Route, Switch, Redirect } from "react-router-dom";
import { compose, lifecycle } from "recompose";
import routes from "./__config__/routes";
import BaseLayout from "./layouts/BaseLayout";
import { getToken, isAuthenticated, type, PrivateRoute } from "./auth";
import { translations, language } from "./i18n";
import Notifier from "./notifications/components/Notifier";
import { getActivities } from "./activities";
import AccessDeniedPage from "./shared/AccessDeniedPage";
import { featuresMap } from "./features";

import moment from "moment";
import "moment/locale/it";

moment.locale("it");

const NotFound = () => <BaseLayout>Work in progress</BaseLayout>;

export const App = ({ isAuthenticated, language, userType }) => {
  return (
    <IntlProvider
      defaultLocale="it"
      key={language}
      locale={language}
      messages={translations[language]}
    >
      <Notifier />

      <FeatureToggles features={featuresMap[userType]}>
        <Switch>
          {routes.map(route => {
            const RouteComponent = route.isPrivate ? PrivateRoute : Route;
            return (
              <RouteComponent
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={
                  route.feature
                    ? () => (
                        <Feature
                          name={route.feature}
                          inactiveComponent={() => <AccessDeniedPage />}
                          activeComponent={() => <route.component />}
                        />
                      )
                    : () => <route.component />
                }
              />
            );
          })}
          {(userType === "5" || userType === "8") && <Redirect from="/" to="/administrators" />}
          {userType === "1" && <Redirect from="/" to="/dashboard" />}

          <PrivateRoute component={NotFound} />
        </Switch>
      </FeatureToggles>
    </IntlProvider>
  );
};

const enhance = compose(
  connect(
    state => ({
      isAuthenticated: isAuthenticated(state),
      userType: type(state),
      language: language(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ getToken, getActivities }, dispatch)
    })
  ),
  lifecycle({
    componentDidMount() {
      const { actions, isAuthenticated } = this.props;
      actions.getActivities();
      if (!isAuthenticated) {
        actions.getToken();
      }
    },

    componentDidUpdate() {
      const { actions, isAuthenticated } = this.props;
      if (!isAuthenticated) {
        actions.getToken();
      }
    }
  })
);
export default enhance(App);
