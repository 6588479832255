import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Buildings = (
  <FormattedMessage id="buildings_list.buildings" defaultMessage="Edifici" />
);
export const Id = <FormattedMessage id="buildings_list.id" defaultMessage="ID" />;

export const FiscalCode = (
  <FormattedMessage id="buildings_list.fiscal_code" defaultMessage="Codice fiscale" />
);

export const Address = <FormattedMessage id="buildings_list.address" defaultMessage="Indirizzo" />;

export const Name = <FormattedMessage id="buildings_list.name" defaultMessage="Nome" />;

export const Status = <FormattedMessage id="buildings_list.status" defaultMessage="Status" />;

export const Administrator = (
  <FormattedMessage id="buildings_list.administrator" defaultMessage="Studio d'amministrazione" />
);

export const Region = <FormattedMessage id="buildings_list.region" defaultMessage="Regione" />;

export const Province = (
  <FormattedMessage id="buildings_list.province" defaultMessage="Provincia" />
);

export const City = <FormattedMessage id="buildings_list.city" defaultMessage="Comune" />;

export const Search = <FormattedMessage id="buildings_list.search" defaultMessage="Ricerca" />;

export const EditRas = (
  <FormattedMessage id="buildings_list.edit_ras" defaultMessage="Modifica RAS" />
);

export const OpenRas = (
  <FormattedMessage id="buildings_list.open_ras" defaultMessage="Visualizza RAS" />
);
export const OpenNotes = (
  <FormattedMessage id="buildings_list.open_notes" defaultMessage="Visualizza note" />
);

export const OpenManagerAssignment = (
  <FormattedMessage
    id="buildings_list.open_manager_assignment"
    defaultMessage="Cambia responsabile"
  />
);
