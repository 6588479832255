import {
  BUILDING_ACTIVITY_DELETE,
  BUILDING_ACTIVITY_UPDATE,
  BUILDING_ACTIVITY_DOCUMENT_GET,
  BUILDING_ACTIVITY_DOCUMENT_ADD,
  BUILDING_ACTIVITY_DOCUMENT_REMOVE,
  BUILDING_ACTIVITY_INDEX,
  CORRECTIVE_ACTION_CREATE,
  CORRECTIVE_ACTION_UPDATE,
  CORRECTIVE_ACTION_DELETE
} from "./types";

export const saveBuildingActivity = ({ buildingActivity }) => {
  return {
    type: BUILDING_ACTIVITY_UPDATE,
    request: {
      url: `/api/internal/v1/ras/building_activities/${buildingActivity.id}`,
      method: "PATCH",
      data: {
        building_activity: buildingActivity
      }
    },
    meta: {
      serialize: {
        ras_activity: {
          valueForRelationship: ({ id }) => ({ id })
        }
      }
    }
  };
};

export const getBuildingActivities = buildingId => {
  return {
    type: BUILDING_ACTIVITY_INDEX,
    request: {
      url: "/api/internal/v1/ras/building_activities",
      method: "GET",
      params: {
        "q[building_id_edificio_eq]": buildingId
      }
    },
    meta: {
      serialize: {
        ras_building_activity: {
          valueForRelationship: ({ id }) => id
        },
        building: {
          valueForRelationship: ({ id }) => id
        }
      }
    }
  };
};

export const deleteBuildingActivity = ({ buildingActivityId }) => {
  return {
    type: BUILDING_ACTIVITY_DELETE,
    request: {
      url: `/api/internal/v1/ras/building_activities/${buildingActivityId}`,
      method: "DELETE"
    }
  };
};

export const getDocuments = buildingActivityId => {
  return {
    type: BUILDING_ACTIVITY_DOCUMENT_GET,
    request: {
      url: `/api/internal/v1/ras/building_activities/${buildingActivityId}`,
      method: "GET"
    },
    meta: {
      buildingActivityId,
      serialize: {}
    }
  };
};

export const addDocument = ({ buildingActivityId, file }) => {
  const form = new FormData();
  form.append("file", file);
  return {
    type: BUILDING_ACTIVITY_DOCUMENT_ADD,
    request: {
      url: `/api/internal/v1/ras/building_activities/${buildingActivityId}/add_document`,
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: form
    },
    meta: {
      serialize: {}
    }
  };
};

export const removeDocument = ({ buildingActivityId, document_key }) => ({
  type: BUILDING_ACTIVITY_DOCUMENT_REMOVE,
  request: {
    url: `/api/internal/v1/ras/building_activities/${buildingActivityId}/remove_document`,
    method: "PATCH",
    data: { document_key }
  },
  meta: {
    serialize: {}
  }
});

export const createCorrectiveAction = ({ buildingActivityId, correctiveAction }) => ({
  type: CORRECTIVE_ACTION_CREATE,
  request: {
    url: `/api/internal/v1/ras/building_activities/${buildingActivityId}/corrective_actions`,
    method: "POST",
    data: { corrective_action: { done: correctiveAction.done, body: correctiveAction.body || "" } }
  }
});
export const updateCorrectiveAction = ({ correctiveActionId, correctiveAction }) => ({
  type: CORRECTIVE_ACTION_UPDATE,
  request: {
    url: `/api/internal/v1/ras/corrective_actions/${correctiveActionId}`,
    method: "PATCH",
    data: { corrective_action: correctiveAction }
  }
});
export const deleteCorrectiveAction = ({ correctiveActionId }) => ({
  type: CORRECTIVE_ACTION_DELETE,
  request: {
    url: `/api/internal/v1/ras/corrective_actions/${correctiveActionId}`,
    method: "DELETE"
  }
});
