import React from "react";
import { SnackbarProvider, withSnackbar } from "notistack";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { consumeNotifications } from "../ducks/actions";

import isEmpty from "lodash/isEmpty";

const Notifier = ({ errors, notices, enqueueSnackbar, actions }) => {
  errors.forEach(error => {
    enqueueSnackbar(error, { variant: "error" });
  });
  if (!isEmpty(errors)) actions.consumeNotifications("error");

  notices.forEach(error => {
    enqueueSnackbar(error, { variant: "success" });
  });
  if (!isEmpty(notices)) actions.consumeNotifications("notice");

  return null;
};

function mapStateToProps(state) {
  return {
    errors: state.notifications.errors,
    notices: state.notifications.notices
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      consumeNotifications: bindActionCreators(consumeNotifications, dispatch)
    }
  };
}

const providedSnackbar = Component => {
  return props => (
    <SnackbarProvider>
      <Component />
    </SnackbarProvider>
  );
};

export default providedSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withSnackbar(Notifier))
);
