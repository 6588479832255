import { createSelector, createStructuredSelector } from "reselect";

const slice = state => state.auth;

const authenticationSlice = createSelector(slice, slice => slice.authentication);

export const token = createSelector(authenticationSlice, slice => {
  return slice.token;
});

export const userId = createSelector(authenticationSlice, slice => {
  return slice.userId;
});

export const userName = createSelector(authenticationSlice, slice => {
  return slice.username;
});

export const isAuthenticated = createSelector(token, userId, (token, userId) => {
  return Boolean(token && userId);
});

export const type = createSelector(authenticationSlice, slice => {
  return slice.userType;
});
export const referId = createSelector(authenticationSlice, slice => slice.referId);

export const user = createStructuredSelector({
  referId: referId,
  userType: type,
  userId: userId
});
