import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { bindActionCreators } from "redux";
import NotesEditorComponent from "./NotesEditorComponent";
import { getBuildings } from "../../buildings/ducks/actions";
import { getBuildingActivities } from "./../../building-activities";
import { createNote, getNotesByBuildingFiscalCode } from "./../ducks/actions";
import { rasEventId } from "./../ducks/selectors";

function createFilePromise(file) {
  return new Promise(resolve => {
    let reader = new FileReader();

    reader.readAsDataURL(file.file);

    reader.onload = function() {
      return resolve({
        binary: reader.result.split(",")[1],
        name: file.file.name
          .split(".")
          .slice(0, -1)
          .join("."),
        ext: file.file.name
          .split(".")
          .slice(1, 2)
          .join(".")
      });
    };
  });
}

function prepareData({ eventId, description, files, activityIdRas }) {
  let data = {
    eventId,
    userIdLogged: JSON.parse(sessionStorage.getItem("user")).userId,
    descr: description,
    tags: "",
    activityIdRas
  };

  if (files.length === 0) {
    return data;
  }

  const filePromises = files.map(createFilePromise);
  return Promise.all(filePromises).then(files => {
    return { ...data, files };
  });
}

const enhance = compose(
  connect(
    state => ({
      eventId: rasEventId(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          getBuildings,
          getBuildingActivities,
          createNote,
          getNotesByBuildingFiscalCode
        },
        dispatch
      )
    })
  ),
  lifecycle({
    componentDidMount() {
      const { buildingId, buildingFiscalCode, actions } = this.props;
      actions.getBuildings();
      if (buildingId && buildingFiscalCode) {
        actions.getBuildingActivities();
        actions.getNotesByBuildingFiscalCode({ buildingFiscalCode });
      }
    }
  }),
  withHandlers({
    onBuildingChange: ({ actions }) => ({ buildingId, buildingFiscalCode }) => {
      actions.getBuildingActivities();
      actions.getNotesByBuildingFiscalCode({ buildingFiscalCode });
    },
    onSubmit: ({ actions, onNoteCreated, eventId }) => async values => {
      const data = await prepareData({
        files: Object.values(values.files),
        eventId,
        description: values.description,
        activityIdRas: values.activityId
      });
      actions.createNote({ data });
    }
  })
);

export default enhance(NotesEditorComponent);
