import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Dashboard = <FormattedMessage id="dashboard.dashboard" defaultMessage="Dashboard" />;
export const Building = <FormattedMessage id="dashboard.building" defaultMessage="Edificio" />;
export const Actions = <FormattedMessage id="dashboard.actions" defaultMessage="Azioni" />;
export const Status = <FormattedMessage id="dashboard.status" defaultMessage="Stato" />;
export const Notes = <FormattedMessage id="dashboard.notes" defaultMessage="Note generali" />;

export const Yes = <FormattedMessage id="shared.status.yes" defaultMessage="Si" />;
export const No = <FormattedMessage id="shared.status.no" defaultMessage="No" />;
export const NearToExpire = (
  <FormattedMessage id="shared.status.near_to_expire" defaultMessage="In scadenza" />
);
export const Expired = <FormattedMessage id="shared.status.expired" defaultMessage="Scaduto" />;
export const ToBeIntegrated = (
  <FormattedMessage id="shared.status.to_be_integrated" defaultMessage="Da Integrare" />
);
export const Missing = (
  <FormattedMessage id="shared.status.missing" defaultMessage="Non presente" />
);
export const All = <FormattedMessage id="dashboard.all" defaultMessage="Tutti" />;
export const Issues = <FormattedMessage id="dashboard.issues" defaultMessage="Problemi" />;
export const Ok = <FormattedMessage id="dashboard.ok" defaultMessage="Ok" />;
export const FilterSubtext = (
  <FormattedMessage
    id="dashboard.filter_subtext"
    defaultMessage="Filtra edifici per stato impianto"
  />
);
export const UserFilterSubtext = (
  <FormattedMessage
    id="dashboard.user_filter_subtext"
    defaultMessage="Filtra edifici per responsabile"
  />
);
export const FilterBuildingVisibility = (
  <FormattedMessage
    id="dashboard.filter_building_visibility"
    defaultMessage="Mostra solo edifici di tua competenza"
  />
);

export const OpenEditBuilding = (
  <FormattedMessage id="dashboard.open_edit_building" defaultMessage="Apri modifica RAS edificio" />
);
export const OpenBuildingNotes = (
  <FormattedMessage id="dashboard.open_building_notes" defaultMessage="Apri note edificio" />
);
