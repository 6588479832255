import React from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

const DatePickerField = ({ field, form, onValueChanged, submitOnChange, ...other }) => {
  const currentError = form.errors[field.name];

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        clearable
        name={field.name}
        value={field.value}
        {...(Boolean(currentError) ? { error: true, helperText: currentError } : {})}
        // if you are using custom validation schema you probably want to pass `true` as third argument
        onChange={date => {
          const value = date ? moment.utc(date.format("YYYY-MM-DD")).utc(): null;
          form.setFieldValue(field.name, value, true);
          if (submitOnChange) setTimeout(form.submitForm, 0);
        }}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerField;
