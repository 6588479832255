import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PageLayout from "../layouts/PageLayout";
import NotesList from "./components/NotesListContainer";
import { getActivities, allActivities } from "../activities";
import { compose, lifecycle } from "recompose";

const enhance = compose(
  connect(
    state => ({ activities: allActivities(state) }),
    dispatch => bindActionCreators({ getActivities }, dispatch)
  ),
  lifecycle({
    componentDidMount() {
      const { getActivities } = this.props;
      getActivities();
    }
  })
);

export default enhance(({ activities }) => {
  return <PageLayout>{activities.length > 0 && <NotesList />}</PageLayout>;
});
