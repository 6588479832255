import React from "react";
import { Paper, Grid } from "@material-ui/core";
import PageLayout from "../../layouts/PageLayout";
import * as Messages from "./Messages";
import BuildingsTable from "./BuildingsTable";
import AdministratorCard from "../../shared/AdministratorCard";

const BuildingListComponent = ({ buildings, administrator }) => {
  return (
    <PageLayout
      title={
        <>
          {Messages.Buildings} {administrator && administrator.brand}
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AdministratorCard administrator={administrator} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <BuildingsTable buildings={buildings} />
          </Paper>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default BuildingListComponent;
