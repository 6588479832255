import { POST_NOTIFICATION, CONSUME_NOTIFICATIONS } from "./const";

import cloneDeep from "lodash/cloneDeep";

const initialState = {
  errors: [],
  notices: []
};

export default (state = initialState, action) => {
  const nextState = cloneDeep(state);

  switch (action.type) {
    case POST_NOTIFICATION: {
      if (action.notificationType === "notice")
        nextState.notices.push(action.message);
      if (action.notificationType === "error")
        nextState.errors.push(action.message);
      return nextState;
    }
    case CONSUME_NOTIFICATIONS: {
      if (action.notificationType === "notice") nextState.notices.length = 0;
      if (action.notificationType === "error") nextState.errors.length = 0;
      return nextState;
    }
    default: {
      return state;
    }
  }
};
