import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BuildingActivityUploaderComponent from "./BuildingActivityUploaderComponent";

import { documents } from "../ducks/selectors";
import { getDocuments, addDocument, removeDocument } from "../ducks/actions";

const enhance = compose(
  connect(
    (state, props) => ({
      documents: documents(props.ba.id)(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          getDocuments,
          addDocument,
          removeDocument
        },
        dispatch
      )
    })
  )
);

export default enhance(BuildingActivityUploaderComponent);
