import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Yes = <FormattedMessage id="shared.status.yes" defaultMessage="Si" />;
export const No = <FormattedMessage id="shared.status.no" defaultMessage="No" />;
export const ToBeIntegrated = (
  <FormattedMessage id="shared.status.to_be_integrated" defaultMessage="Da Integrare" />
);
export const NearToExpire = (
  <FormattedMessage id="shared.status.near_to_expire" defaultMessage="In scadenza" />
);
export const Expired = <FormattedMessage id="shared.status.expired" defaultMessage="Scaduto" />;
export const Missing = (
  <FormattedMessage id="shared.status.missing" defaultMessage="Non presente" />
);
export const It = <FormattedMessage id="shared.language_selector.it" defaultMessage="Italiano" />;

export const En = <FormattedMessage id="shared.language_selector.en" defaultMessage="Inglese" />;

export const AccessDenied = (
  <FormattedMessage
    id="shared.access_denied_page.access_denied"
    defaultMessage="Non hai il permesso di entrare qui dentro!"
  />
);

export const FieldRequired = (
  <FormattedMessage id="shared.field_required" defaultMessage="Campo obbligatorio" />
);
