import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import Dropzone from "react-dropzone-uploader";
import {
  InsertChart,
  InsertDriveFile,
  Notes,
  Image,
  CloudUpload,
  DeleteOutlined
} from "@material-ui/icons";
import {
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  Tooltip
} from "@material-ui/core";
import { Feature } from "@paralleldrive/react-feature-toggles";
import "react-dropzone-uploader/dist/styles.css";

import config from "../../config";
import * as Messages from "./Messages";
import * as Features from "./../../features";

const styles = {
  inputLabel: {
    fontSize: "small",
    textAlign: "center",
    padding: 8
  },
  dropzone: {
    minHeight: 48,
    overflow: "hidden"
  }
};

const useStyles = makeStyles({
  dropzone: { padding: 8 }
});

const useListStyles = makeStyles({
  list: { maxHeight: 240, overflow: "auto", marginTop: 8 }
});

const SubmitButton = ({ onSubmit }) => (
  <Tooltip placement="top" title={Messages.DropzoneAdd}>
    <IconButton size="small" onClick={onSubmit}>
      <CloudUpload />
    </IconButton>
  </Tooltip>
);

const DocumentUploader = ({ onDocumentUploaded }) => {
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach(file => {
      onDocumentUploaded(file);
      file.remove();
    });
  };
  return (
    <Dropzone
      accept="image/*,audio/*,video/*,.pdf,.doc,.docx,.xls,.xlsx,.csv"
      maxFiles={1}
      inputContent={Messages.DropzoneHelperText}
      SubmitButtonComponent={SubmitButton}
      onSubmit={handleSubmit}
      styles={styles}
    />
  );
};

const FileIcon = ({ url }) => {
  const fileExtension = url.split(".").pop();
  switch (fileExtension) {
    case "doc":
    case "docx":
    case "pdf": {
      return <Notes />;
    }
    case "xls":
    case "xlsx": {
      return <InsertChart />;
    }
    case "jpg":
    case "bmp":
    case "png": {
      return <Image />;
    }
    default: {
      return <InsertDriveFile />;
    }
  }
};

const FilePreview = ({ documents, buildingAssignedState, onDocumentDeleted }) => {
  const handleDelete = document_key => () => onDocumentDeleted(document_key);
  const classes = useListStyles();
  return (
    <List dense className={classes.list}>
      {isEmpty(documents) ? (
        <ListItem>
          <ListItemText primary={Messages.NoDocument} />
        </ListItem>
      ) : (
        documents.map(document => (
          <ListItem key={document.key}>
            <ListItemAvatar>
              <Avatar>
                <FileIcon url={document.url} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${config.apiBaseUrl}${document.url}`}
                >
                  {document.filename}
                </a>
              }
            />
            <Feature
              name={Features.buildingFeatureMap[buildingAssignedState]}
              activeComponent={() => (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={handleDelete(document.key)}>
                    <DeleteOutlined />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            />
          </ListItem>
        ))
      )}
    </List>
  );
};
const BuildingActivityUploaderComponent = ({ ba, buildingAssignedState, documents, actions, callback }) => {
  const handleUpload = buildingActivityId => ({ file }) => {
    actions
      .addDocument({ buildingActivityId, file })
      .then(() => actions.getDocuments(buildingActivityId))
      .then(() => callback && callback());
  };

  const handleDelete = buildingActivityId => document_key => {
    actions
      .removeDocument({ buildingActivityId, document_key })
      .then(() => actions.getDocuments(buildingActivityId))
      .then(() => callback && callback());
  };
  useEffect(() => {
    actions.getDocuments(ba.id);
  }, [actions, ba.id]);
  const classes = useStyles();
  return (
    <div className={classes.dropzone}>
      <Feature
        name={Features.buildingFeatureMap[buildingAssignedState]}
        activeComponent={() => <DocumentUploader onDocumentUploaded={handleUpload(ba.id)} />}
      />
      <FilePreview
        documents={documents}
        onDocumentDeleted={handleDelete(ba.id)}
        buildingAssignedState={buildingAssignedState}
      />
    </div>
  );
};

export default BuildingActivityUploaderComponent;
