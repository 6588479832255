import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { lifecycle, compose } from 'recompose';

import { administrators } from '../ducks/selectors';
import { getAdministrators } from '../ducks/actions';

import { startDashboardSaga } from '../../dashboard/ducks/actions';
import { buildings } from '../../dashboard/ducks/selectors';

import AdministratorListComponent from './AdministratorListComponent';

const enhance = compose(
  connect(
    (state) => ({
      administrators: administrators(state)
    }),
    (dispatch) => ({
      actions: bindActionCreators({ getAdministrators, startDashboardSaga }, dispatch)
    })
  ),
  lifecycle({
    componentDidMount() {
      const { actions } = this.props;
      actions.getAdministrators();
      actions.startDashboardSaga({});
    }
  })
);

export default enhance(AdministratorListComponent);
