import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Email = <FormattedMessage id="login.email" defaultMessage="Email" />;
export const Enter = <FormattedMessage id="login.enter" defaultMessage="ENTRA" />;
export const ForgotPassword = (
  <FormattedMessage id="login.forgot_password" defaultMessage="Password dimenticata?" />
);
export const Password = <FormattedMessage id="login.password" defaultMessage="Password" />;
export const Title = <FormattedMessage id="login.title" defaultMessage="Modulo RAS" />;
export const RememberMe = <FormattedMessage id="login.remember_me" defaultMessage="Ricordami" />;
export const SignUpHelper = (
  <FormattedMessage id="login.sing_up_helper" defaultMessage="Non hai un account? Registrati" />
);
export const Copyright = <FormattedMessage id="login.copyright" defaultMessage="Copyright © " />;
export const Brand = <FormattedMessage id="login.brand" defaultMessage="Copernico CRM" />;
export const LoginErrorMessage = (
  <FormattedMessage
    id="login.api.login_error_message"
    defaultMessage="Username o password non valida"
  />
);
