import React from 'react';
import PageLayout from '../../layouts/PageLayout';
import * as Messages from './Messages';
import ActivityTable from './ActivityTable';

export const ActivityListComponent = ({ activities, parentActivities, actions }) => {
  return (
    <PageLayout title={Messages.ActivityListTitle}>
      <ActivityTable
        activities={activities}
        parentActivities={parentActivities}
        onCreate={actions.createActivity}
        onUpdate={actions.updateActivity}
        onDelete={actions.deleteActivity}
      />
    </PageLayout>
  );
};

export default ActivityListComponent;
