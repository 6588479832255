import React from "react";
import { MenuItem, Button, Menu, Box } from "@material-ui/core";
import * as Messages from "./Messages";

export default ({ currentLang, actions, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = lang => () => {
    actions.setLanguage(lang);
    setAnchorEl(null);
  };
  return (
    <Box {...props}>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <img src={`/${currentLang}.png`} height={18} width={25} alt="" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem value="it" onClick={handleClose("it")}>
          <img src="/it.png" height={18} width={25} alt="" />
          <span>&nbsp;{Messages.It}</span>
        </MenuItem>
        <MenuItem value="en" onClick={handleClose("en")}>
          <img src="/en.png" height={18} width={25} alt="" />
          <span>&nbsp;{Messages.En}</span>
        </MenuItem>
      </Menu>
    </Box>
  );
};
