import React from "react";
import { connect } from "react-redux";
import { Select, MenuItem } from "@material-ui/core";
import { isEmpty, isNull } from "lodash";

import { buildingActivitiesByBuildingId } from "../ducks/selectors";
import * as Messages from "./Messages";

const BuildingActivitiesSelect = ({
  buildingActivities = [],
  field,
  form,
  onSelectChange,
  submitOnChange,
  dispatch,
  ...other
}) => {
  const handleChange = event => {
    form.setFieldValue(field.name, event.target.value, false);

    if (submitOnChange) {
      setTimeout(form.submitForm, 0);
    }
    if (onSelectChange) onSelectChange({ activityId: event.target.value });
  };

  return (
    <Select
      displayEmpty
      {...field}
      onChange={handleChange}
      disabled={isEmpty(form.values.buildingId)}
    >
      <MenuItem value="">{Messages.None}</MenuItem>
      {buildingActivities.map(({ ras_activity }) =>
        isNull(ras_activity.parent_id) ? (
          <MenuItem key={ras_activity.id} value={ras_activity.id}>
            <strong>{ras_activity.title}</strong>&nbsp;
          </MenuItem>
        ) : (
          <MenuItem dense key={ras_activity.id} value={ras_activity.id}>
            &nbsp;&nbsp;{ras_activity.title}&nbsp;
          </MenuItem>
        )
      )}
    </Select>
  );
};

const enhance = connect((state, { buildingId }) => ({
  buildingActivities: buildingActivitiesByBuildingId(buildingId)(state)
}));

export default enhance(BuildingActivitiesSelect);
