import { createSelector } from "reselect";
import { getQuery } from "redux-saga-requests";
import { ADMINISTRATOR_SHOW } from "./types";
import { filter, reject, cloneDeep } from 'lodash';

const getSlice = state => ({dashboard: state.dashboard, administrators: state.administrators});

export const administrators = createSelector(
  getSlice,
  slice => {

    const admins = cloneDeep((slice.administrators.list.data || {}).admins || []);

    admins.forEach((admin) => {
      const adminBuildings = filter(slice.dashboard.buildings, {id_amm: parseInt(admin.idAmm)});

      admin.problemBuildingActivityCounter = 0;
      admin.correctiveActionCounter = 0;
      admin.unDoneCorrectiveActionCounter = 0;
      admin.doneCorrectiveActionCounter = 0;
      admin.unVerifiedCorrectiveActionCounter = 0;
      admin.verifiedCorrectiveActionCounter = 0;

      adminBuildings.forEach((b) => {
        admin.problemBuildingActivityCounter += reject(reject(b.ras_building_activities, {}), {status: "yes"}).length;

        b.ras_building_activities.forEach((ba) => {
          admin.doneCorrectiveActionCounter += filter(ba.ras_corrective_actions, {done: true}).length;
          admin.unDoneCorrectiveActionCounter += filter(ba.ras_corrective_actions, {done: false}).length;
          admin.unVerifiedCorrectiveActionCounter += filter(ba.ras_corrective_actions, (ca)=>{ return ca.done == true && (ca.verified == false || ca.verified == null) }).length;
          admin.verifiedCorrectiveActionCounter += filter(ba.ras_corrective_actions, { done: true, verified: true }).length;
          admin.correctiveActionCounter += ba.ras_corrective_actions.length;
        });
      });
    });

    return admins;
  }
);

const administratorDetailQuery = getQuery({
  type: ADMINISTRATOR_SHOW,
  requestSelector: state => state.administrators.detail
});

export const administratorDetail = createSelector(administratorDetailQuery, query => query.data);
