import React, { useState } from "react";
import { compose, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { forEach } from "lodash";
import { Link } from "react-router-dom";
// import { Tooltip, IconButton } from "@material-ui/core";
// import { FormatListBulleted, PlaylistAdd } from "@material-ui/icons";
import {
  EditingState,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableEditColumn
} from "@devexpress/dx-react-grid-material-ui";
import { Tooltip, IconButton } from "@material-ui/core";
import { SupervisorAccount } from "@material-ui/icons";
// import { Feature } from "@paralleldrive/react-feature-toggles";
import TableComponentBase from "../../shared/TableComponentBase";
import AssignedState from "../../shared/AssignedState";
// import SelectTypeProvder from "../../shared/SelectTypeProvider";
// import BuildingNoteDialog from "../building-detail/BuildingNoteDialog";
import ManagerAssignmentDialog from "../../managers/components/ManagerAssignmentDialog";
import { associate, disassociate, getManagers, managers } from "../../managers";
import { user } from "../../auth";
import * as Messages from "./Messages";
// import * as Features from "../../features";
// import { Feature } from "@paralleldrive/react-feature-toggles";

const columns = [
  {
    name: "status",
    title: Messages.Status,
    getCellValue: row => (
      <AssignedState assignedState={row.buildingAssignedState || row.assigned_state} />
    )
  },
  {
    name: "buildingName",
    title: Messages.Name
  },
  {
    name: "buildingAddress",
    title: Messages.Address,
    getCellValue: row => `${row.buildingAddress}, ${row.buildingCity} (${row.buildingProvince})`
  },
  {
    name: "buildingFiscalCode",
    title: Messages.FiscalCode,
    getCellValue: ({ buildingFiscalCode }) => buildingFiscalCode.toUpperCase()
  },
  {
    name: "ammDescription",
    title: Messages.Administrator
  }
];

const BuildingNameLinkTypeProvider = props => {
  return (
    <DataTypeProvider
      {...props}
      formatterComponent={({ row }) => (
        <Link to={`/buildings/${row.buildingId}`}>{row.buildingName}</Link>
      )}
    />
  );
};

/*
const EditRasButton = ({ buildingId }) => {
  return (
    <Tooltip placement="top" title={Messages.EditRas}>
      <IconButton
        color="primary"
        size="small"
        to={`/buildings/${buildingId}`}
        component={Link}
      >
        <PlaylistAdd />
      </IconButton>
    </Tooltip>
  );
};

const OpenRasButton = ({ buildingId }) => (
  <Tooltip placement="top" title={Messages.OpenRas}>
    <IconButton color="primary" size="small" to={`/buildings/${buildingId}`} component={Link}>
      <FormatListBulleted />
    </IconButton>
  </Tooltip>
);
*/

/*
const OpenNoteButton = ({ onClick }) => (
  <Tooltip placement="top" title={Messages.OpenNotes}>
    <IconButton color="primary" size="small" onClick={onClick}>
      <Comment />
    </IconButton>
  </Tooltip>
);
*/

const OpenManagerAssignmentButton = ({ onClick }) => (
  <Tooltip placement="top" title={Messages.OpenManagerAssignment}>
    <IconButton color="primary" size="small" onClick={onClick}>
      <SupervisorAccount />
    </IconButton>
  </Tooltip>
);

const ActionsCell = ({ onOpenNoteClick, onOpenManagerAssignmentClick, row, children, ...rest }) => {
  return (
    <Table.Cell {...rest}>
      {/* <Feature
        name={Features.buildingFeatureMap[row.assigned_state]}
        activeComponent={() => <EditRasButton buildingId={row.buildingId} />}
        inactiveComponent={() => <OpenRasButton buildingId={row.buildingId} />}
      /> */}

      {/*
      <OpenNoteButton
        onClick={() => onOpenNoteClick({ id_edificio: row.buildingId, nome: row.buildingName })}
      />
      */}

      <OpenManagerAssignmentButton
        onClick={() =>
          onOpenManagerAssignmentClick({
            buildingId: row.buildingId,
            buildingName: row.buildingName
          })
        }
      />
    </Table.Cell>
  );
};

const columnExtensions = [
  {
    align: "center",
    columnName: "status",
    width: 80
  }
];

const BuildingsTable = ({ buildings, managers, onManagerChange }) => {
  // const [showNoteDialog, setShowNoteDialog] = useState();
  const [showManagerAssignmentDialog, setShowManagerAssignmentDialog] = useState();
  return (
    <>
      <Grid rows={buildings} columns={columns}>
        {/*<AssignedStateTypeProvider for={["buildingName"]} />*/}
        {/* <SelectTypeProvder
          title="Ciao"
          noneLabel="Noneee"
          collection={managers}
          onChange={onManagerChange}
          for={["associatedManagerId"]}
        /> */}
        <FilteringState />
        <EditingState />
        <IntegratedFiltering />
        <BuildingNameLinkTypeProvider for={["buildingName"]} />
        <Table tableComponent={TableComponentBase} columnExtensions={columnExtensions} />
        <TableHeaderRow />
        <TableFilterRow />
        <FilteringState columnExtensions={[{ columnName: "status", filteringEnabled: false }]} />

        <TableEditColumn
          showEditCommand
          cellComponent={props => (
            <ActionsCell
              // onOpenNoteClick={setShowNoteDialog}
              onOpenManagerAssignmentClick={setShowManagerAssignmentDialog}
              {...props}
            />
          )}
        />
      </Grid>
      {/* {Boolean(showNoteDialog) && (
        <BuildingNoteDialog
          navigable
          building={showNoteDialog}
          open={Boolean(showNoteDialog)}
          onClose={() => setShowNoteDialog(undefined)}
        />
      )} */}
      {Boolean(showManagerAssignmentDialog) && (
        <ManagerAssignmentDialog
          building={showManagerAssignmentDialog}
          open={Boolean(showManagerAssignmentDialog)}
          onClose={() => setShowManagerAssignmentDialog(undefined)}
        />
      )}
    </>
  );
};

const enhance = compose(
  connect(
    state => ({
      user: user(state),
      managers: managers(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          associate,
          disassociate,
          getManagers
        },
        dispatch
      )
    })
  ),
  lifecycle({
    componentDidMount() {
      const { user, actions } = this.props;
      actions.getManagers({ referId: user.referId, userType: user.userType });
    }
  }),
  withHandlers({
    onAssociationChange: ({ buildingId, associate, disassociate }) => async ({ value, row }) => {
      forEach(
        row.prevAssociatedManagerIds,
        async buildingManagerId => await disassociate({ buildingManagerId })
      );
      await associate({ buildingId, userId: value });
      // TODO: aggiungere il refresh
    }
  })
  // withProps(({ buildings, managers }) => {
  //   // TODO: qui dovrebbe esserci una macumba che dati
  //   // managers e buildings mi tira fuori un oggetto buildings fatto così:
  //   // {buildings: [{
  //   //   ...builing,
  //   //   prevAssociatedManagerIds: managersByBuildingId(buidling.id),
  //   //   associatedManagerId: managersByBuildingId(buidling.id)[0]
  //   // }]}
  //   return {};
  // })
);

export default enhance(BuildingsTable);
