import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  Tooltip,
  Fab,
  Button,
  DialogActions
} from "@material-ui/core";
import { SwapVert } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import * as Messages from "./Messages";

const ToggleAssignmentDialog = ({ building = {}, onConfirm }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const handleAction = () =>
    onConfirm().then(() => {
      closeDialog();
    });

  return (
    <>
      <Tooltip
        placement="top"
        title={
          building.assigned_state === "administrator_assigned"
            ? Messages.RasAssignmentTooltip
            : Messages.AdministratorAssignmentTooltip
        }
      >
        <Fab onClick={openDialog} color="primary">
          <SwapVert />
        </Fab>
      </Tooltip>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm" fullScreen={fullScreen}>
        <DialogTitle>{Messages.ToggleAssignmentDialogTitle}</DialogTitle>
        <DialogContent>
          {building.assigned_state === "administrator_assigned"
            ? Messages.RasAssignmentDialogText
            : Messages.AdministratorAssignmentDialogText}
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            {Messages.Cancel}
          </Button>
          <Button onClick={handleAction} color="primary" autoFocus>
            {Messages.Confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToggleAssignmentDialog;
