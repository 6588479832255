import React from "react";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";

import * as Messages from "./Messages";
import NotesList from "./NotesListContainer";
import Modal from "../../modal/Modal";

const useStyles = makeStyles(theme => ({
  customBadge: {
    backgroundColor: "lightblue",
    marginTop: "3px"
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  notesList: {
    padding: theme.spacing(5)
  }
}));

const NotesListModal = ({ buildingId, onClose, open }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <>
      {open && (
        <Modal
          title={Messages.Notes}
          fullWidth
          maxWidth="lg"
          fullScreen={fullScreen}
          open={open}
          onClose={onClose}
        >
          <Grid container className={classes.notesList}>
            <NotesList filters={{ buildingId: String(buildingId || "") }} />
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default NotesListModal;
