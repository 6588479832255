import React from "react";
import { makeStyles } from "@material-ui/core";
import { LockOpenOutlined, LockOutlined } from "@material-ui/icons";
import { Feature } from "@paralleldrive/react-feature-toggles";

import * as Features from "../features";

const useStyles = makeStyles(() => ({
  redIcon: { color: "red", marginRight: 4 },
  greenIcon: { color: "green", marginRight: 4 }
}));

export default ({assignedState}) => {
  const classes = useStyles();

  return (
    <Feature
        name={Features.buildingFeatureMap[assignedState]}
        activeComponent={() => <LockOpenOutlined className={classes.greenIcon} />}
        inactiveComponent={() => <LockOutlined className={classes.redIcon} />}
    />
  );
};