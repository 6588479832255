import React from "react";
import { TextField, Grid } from "@material-ui/core";
import * as Messages from "./Messages";

export default ({ values, errors, touched, handleChange }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <TextField
        fullWidth
        required
        name="userName"
        label={Messages.Username}
        error={!!errors.userName && touched.userName}
        helperText={errors.userName}
        value={values.userName}
        onChange={handleChange}
      />
    </Grid>

    <Grid item xs={12}>
      <TextField
        fullWidth
        required
        name="name"
        label={Messages.FullName}
        error={!!errors.name && touched.name}
        helperText={errors.name}
        value={values.name}
        onChange={handleChange}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        required
        name="email"
        label={Messages.Email}
        error={!!errors.email && touched.email}
        helperText={errors.email}
        value={values.email}
        onChange={handleChange}
      />
    </Grid>
  </Grid>
);
