import { Deserializer } from "jsonapi-serializer";
import axios from "../../axios";
import { put, take, select, takeLatest, call } from "redux-saga/effects";
import { isEmpty } from "lodash";
import { GET_BUILDINGS } from "../../buildings";
import { ACTIVITIES_INDEX, getActivities, activitiesMap } from "../../activities";
import { getBuildings } from "../../buildings";
import { success } from "redux-saga-requests";
import { START_DASHBOARD_SAGA } from "./types";
import { startDashboardSaga, endDashboardSaga } from "./actions";

function getBuilding(buildingId) {
  return axios
    .request({
      url: "api/internal/v1/ras/buildings/" + buildingId
    })
    .then(({ data }) => {
      return new Promise((resolve, reject) => {
        new Deserializer({
          keyForAttribute: "underscore_case",
          ras_activity: {
            valueForRelationship: relationship => relationship
          },
          building: {
            valueForRelationship: relationship => relationship
          }
        }).deserialize(data, (err, json) => {
          if (err) {
            reject(err);
          } else {
            resolve(json);
          }
        });
      });
    });
}

function* handleSaga(action) {
  const activities = yield select(activitiesMap);

  if (isEmpty(activities)) {
    yield put(getActivities());
    yield take(success(ACTIVITIES_INDEX));
    yield put(startDashboardSaga(action.filters));
  }
  yield put(getBuildings(action.filters));
  const { data: copernicoBuildings } = yield take(success(GET_BUILDINGS));
  let buildings = [];
  for (const copernicoBuilding of copernicoBuildings.buildings.map(x => ({
    buildingId: x.buildingId,
    read_note_counters: x.read_note_counters,
    unread_note_counters: x.unread_note_counters
  }))) {
    try {
      const data = yield call(getBuilding, copernicoBuilding.buildingId);
      buildings = [
        ...buildings,
        {
          ...data,
          read_note_counters: copernicoBuilding.read_note_counters,
          unread_note_counters: copernicoBuilding.unread_note_counters
        }
      ];
    } catch (err) {}
  }

  yield put(
    endDashboardSaga({
      buildings,
      activities
    })
  );
}

export default function* dashboardSaga() {
  yield takeLatest(START_DASHBOARD_SAGA, handleSaga);
}
