import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { bindActionCreators } from 'redux';
import ActivityListComponent from './ActivityListComponent';
import { allActivities, parentActivities } from '../ducks/selectors';
import { getActivities, createActivity, updateActivity, deleteActivity } from '../ducks/actions';

const enhance = compose(
  connect(
    (state) => ({
      activities: allActivities(state),
      parentActivities: parentActivities(state)
    }),
    (dispatch) => ({
      actions: bindActionCreators({ getActivities, createActivity, updateActivity, deleteActivity }, dispatch)
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.actions.getActivities();
    }
  })
);

export default enhance(ActivityListComponent);
