import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BaseLayout from "./BaseLayout";

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: 20
  }
}));

const Summary = ({ render, classes, ...rest }) => {
  return (
    <Grid item xs={12} className={classes.margin}>
      {render}
    </Grid>
  );
};

const Content = ({ children, classes, ...rest }) => {
  return (
    <Grid item xs={12} className={classes.margin}>
      {children}
    </Grid>
  );
};

const Filter = ({ render, classes, ...rest }) => {
  return (
    <Grid item xs={12} className={classes.margin}>
      {render}
    </Grid>
  );
};

const PageLayout = ({ title, summary, children, filter }) => {
  const classes = useStyles();
  return (
    <BaseLayout title={title}>
      <Grid container>
        {Boolean(filter) && <Filter render={filter} classes={classes} />}
        {Boolean(summary) && <Summary render={summary} classes={classes} />}
        <Content classes={classes}>{children}</Content>
      </Grid>
    </BaseLayout>
  );
};

PageLayout.defaultProps = {
  title: "",
  summary: false,
  renderFilter: false,
  content: false
};

PageLayout.propTypes = {};

export default PageLayout;
