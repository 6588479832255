import React, { useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import * as Messages from "./Messages";
import BuildingAssociationsTable from "./BuildingAssociationsTableContainer";
import Modal from "../../modal/Modal";

const BuildingAssociationsDialog = ({ userId }) => {
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  return (
    <>
      <Tooltip placement="top" title={Messages.EditBuildingList}>
        <IconButton size="small" onClick={openDialog} color="primary">
          <EditOutlined />
        </IconButton>
      </Tooltip>
      {Boolean(open) && (
        <Modal open={open} onClose={closeDialog} title={Messages.BuildingAssociation}>
          <BuildingAssociationsTable userId={userId} />
        </Modal>
      )}
    </>
  );
};

export default BuildingAssociationsDialog;
