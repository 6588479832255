import baseConfig from "./base";

const config = {
  appEnv: "dist",
  historyConfig: {
    basename: "/"
  },
  apiMock: false,
  apiBaseUrl: "https://ruby.copernicocrm.it"
};

export default Object.freeze(Object.assign({}, baseConfig, config));
