import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import BuildingListComponent from "./BuildingListComponent";
import { setFilters, getBuildings } from "./../ducks/actions";
import { buildings } from "./../ducks/selectors";
import withAdministratorDetail from "../../shared/withAdministratorDetail";

const enhance = compose(
  withRouter,
  connect(
    state => ({
      buildings: buildings(state)
    }),
    dispatch => ({
      actions: bindActionCreators(
        {
          getBuildings,
          setFilters
        },
        dispatch
      )
    })
  ),
  lifecycle({
    componentDidMount() {
      const { actions, match } = this.props;
      actions.setFilters({ "q[id_amm_eq]": match.params.id });
    }
  }),
  withAdministratorDetail
  // withLoader({ loaderkey: BUILDING_INDEX_KEY })
);

export default enhance(BuildingListComponent);
