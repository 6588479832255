import React, { useState } from "react";
import { map, isEmpty } from "lodash";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
  LinearProgress,
  Divider,
  IconButton
} from "@material-ui/core";
import { Add, SupervisorAccount } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { Feature } from "@paralleldrive/react-feature-toggles";

import { BuildingActivity } from "../../building-activities";
import BuildingNotFoundDialog from "./BuildingNotFoundDialog";
import PageLayout from "../../layouts/PageLayout";
import * as Messages from "./Messages";
import ToggleAssignmentDialog from "./ToggleAssignmentDialog";
import * as Features from "./../../features";
import { useLocation } from "react-router-dom";
import { parse as parseQueryString } from "query-string";
import BuildingNoteDialog from "./BuildingNoteDialog";
import DeleteDialog from "../../building-activities/components/DeleteDialog";
import { ManagerAssignmentDialog } from "../../managers";

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    marginBottom: 32
  },
  card2: {
    width: "100%",
    marginBottom: 32
  },
  container: {
    marginBottom: 32
  },
  dialog: {
    textAlign: "center"
  },
  largeIcon: {
    marginTop: 16,
    transform: "scale(1.8)"
  },
  addButton: {
    marginTop: 12
  },
  selectActivity: {
    minWidth: 350
  }
}));

const OpenManagerAssignmentButton = ({ onClick }) => (
  <Tooltip placement="top" title={Messages.OpenManagerAssignment}>
    <IconButton color="primary" size="small" onClick={onClick}>
      <SupervisorAccount />
    </IconButton>
  </Tooltip>
);

const BuildingDetailComponent = ({
  actions,
  loading,
  error,
  building,
  associatedManagers,
  buildingActivities,
  missingActivities
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { activityId: activityIdFromQueryString } = parseQueryString(location.search);
  const { id_edificio, nome, codfisc, indirizzo, citta, provincia, assigned_state } =
    building || {};

  const [activityId, setActivityId] = useState();
  const [showNoteDialog, setShowNoteDialog] = useState(false);
  const [showManagerAssignmentDialog, setShowManagerAssignmentDialog] = useState();

  const handleCreateActivity = () => {
    actions
      .createBuildingActivities({
        buildingId: id_edificio,
        activityId
      })
      .then(() => actions.startGetBuildingSaga({ buildingId: id_edificio }));
    setActivityId("");
  };

  const handleDelete = ({ buildingActivity }) =>
    actions
      .deleteBuildingActivity({ buildingActivityId: buildingActivity.id })
      .then(() => actions.startGetBuildingSaga({ buildingId: buildingActivity.building.id }));
  return (
    <PageLayout title={Messages.Title}>
      {loading && <LinearProgress />}
      {!error && building && (
        <>
          <Card className={classes.card}>
            {/* <CardHeader title={nome} subheader={<strong>{codfisc}</strong>} /> */}
            <CardContent>
              <Grid
                container
                spacing={2}
                // alignItems="flex-start"
                // justify="space-between"
              >
                <Grid item sm={5} xs={12}>
                  <Typography variant="h6">{Messages.Building}</Typography>
                  <Divider />
                  <br />
                  <Typography variant="h5">
                    <strong>{nome}</strong>
                  </Typography>
                  <Typography variant="h6">{codfisc}</Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {`${indirizzo}, ${citta} (${provincia})`}
                  </Typography>
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Typography variant="h6">
                    <OpenManagerAssignmentButton
                      onClick={() =>
                        setShowManagerAssignmentDialog({
                          buildingId: id_edificio,
                          buildingName: nome
                        })
                      }
                    />
                    {Messages.Manager}
                  </Typography>
                  <Divider />
                  <br />
                  {!isEmpty(associatedManagers) ? (
                    <>
                      <Typography variant="h5">
                        <strong>{associatedManagers[0].nome}</strong>
                      </Typography>
                      <Typography variant="h6">{associatedManagers[0].email}</Typography>
                    </>
                  ) : (
                    Messages.NoManager
                  )}
                </Grid>
                <Grid item sm={2} xs={12} container justify="flex-end" spacing={2}>
                  <Feature
                    name={Features.buildingFeatureMap[assigned_state]}
                    activeComponent={() => (
                      <Grid item>
                        <ToggleAssignmentDialog
                          building={building}
                          onConfirm={() =>
                            actions
                              .toggleAssignedState({ buildingId: id_edificio })
                              .then(() => actions.startGetBuildingSaga({ buildingId: id_edificio }))
                          }
                        />
                      </Grid>
                    )}
                  />
                  {/*
                  <Grid item>
                    <Tooltip placement="top" title={Messages.PrintReportTitle}>
                      <Fab color="primary">
                        <Print />
                      </Fab>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip placement="top" title={Messages.NotesTitle}>
                      <Fab color="primary" onClick={() => setShowNoteDialog(true)}>
                        <Message />
                      </Fab>
                    </Tooltip>
                  </Grid>
                  */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Feature
            name={Features.buildingFeatureMap[assigned_state]}
            activeComponent={() => (
              <>
                <Grid container justify="flex-end" spacing={2}>
                  <Grid item>
                    <FormControl className={classes.selectActivity}>
                      <InputLabel>{Messages.SelectActivity}</InputLabel>
                      <Select
                        displayEmpty
                        value={activityId}
                        onChange={event => setActivityId(event.target.value)}
                      >
                        {missingActivities.map(ma => (
                          <MenuItem key={ma.id} value={ma.id}>
                            {ma.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Tooltip placement="top" title={Messages.AddActivity}>
                      <span>
                        <Button
                          disabled={!activityId}
                          className={classes.addButton}
                          onClick={handleCreateActivity}
                          variant="contained"
                          color="primary"
                        >
                          <Add />
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
                <br />
              </>
            )}
          />
          {map(buildingActivities, (buildingActivity, key) => (
            <div key={key} className={classes.container}>
              {buildingActivity.map(ba => {
                if (ba.parent) {
                  return (
                    <Grid container justify="space-between" alignItems="center" key={ba.id}>
                      <Grid item>
                        <Typography variant="h6">
                          <strong>{ba.activity.code}</strong>&nbsp;
                          {ba.title}&nbsp;
                          <small>{ba.activity.description}</small>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Feature
                          name={Features.buildingFeatureMap[assigned_state]}
                          activeComponent={() => (
                            <DeleteDialog buildingActivity={ba} onDelete={handleDelete} />
                          )}
                        />
                      </Grid>
                    </Grid>
                  );
                }

                return (
                  <BuildingActivity
                    key={`BuildingActivity_${ba.id}`}
                    buildingAssignedState={assigned_state}
                    buildingFiscalCode={codfisc}
                    buildingActivity={ba}
                    requestedActivityId={ba.activityId === activityIdFromQueryString}
                    onRefresh={() => actions.startGetBuildingSaga({ buildingId: id_edificio })}
                  />
                );
              })}
              <Typography variant="caption">{buildingActivity[0].activity.default_note}</Typography>
            </div>
          ))}
        </>
      )}
      {error && <BuildingNotFoundDialog classes={classes} />}
      {showNoteDialog && (
        <BuildingNoteDialog
          building={building}
          open={showNoteDialog}
          onClose={() => setShowNoteDialog(false)}
        />
      )}
      {Boolean(showManagerAssignmentDialog) && (
        <ManagerAssignmentDialog
          building={showManagerAssignmentDialog}
          open={Boolean(showManagerAssignmentDialog)}
          onClose={() => setShowManagerAssignmentDialog(undefined)}
        />
      )}
    </PageLayout>
  );
};

export default BuildingDetailComponent;
