import { createSelector } from "reselect";
import moment from "moment";
import { getQuery } from "redux-saga-requests";
import { filter, map, sortBy, flatten, isNull } from "lodash";
import { activitiesMap } from "../../activities";
import { GET_BUILDINGS } from "./types";

const buildingListSlice = state => state.buildings.list;
const buildingDetailSlice = state => state.buildings.detail || {};

export const buildingsQuery = getQuery({
  type: GET_BUILDINGS,
  multiple: true,
  requestSelector: buildingListSlice
});

export const buildings = createSelector(buildingsQuery, query =>
  map(query.data.buildings, ({ ras_building_decorator, ...building }) => ({
    ...ras_building_decorator,
    ...building
  }))
);

export const buildingsWithManager = createSelector(buildingsQuery);

export const building = createSelector(buildingDetailSlice, slice => slice.building);
export const isError = createSelector(buildingDetailSlice, slice => slice.error);
export const isLoading = createSelector(buildingDetailSlice, slice => slice.loading);
export const filters = createSelector(buildingDetailSlice, slice => slice.filters);

export const buildingActivities = createSelector(buildingDetailSlice, slice =>
  map(slice.buildingActivities, ba => ({
    ...ba,
    status: isNull(ba.expiration_date)
      ? ba.status
      : moment(ba.expiration_date).isBefore()
      ? "expired"
      : moment(ba.expiration_date)
          .subtract(7, "days")
          .isBefore()
      ? "near_to_expire"
      : ba.status,
    correctiveActions: ba.ras_corrective_actions,
    versions: [
      {
        id: 0,
        event: "update",
        created_at: moment(),
        building_activity: ba
      },
      ...ba.versions
    ]
  }))
);

export const tree = createSelector(buildingActivities, activitiesMap, (bas, activitiesMap) => {
  const parents = map(
    filter(bas, ba => ba.activity.parent_id === null),
    x => ({ ...x, activity: activitiesMap[x.activity.id] })
  );
  const result = map(parents, p => ({
    ...p,
    children: map(
      filter(bas, ba => String(ba.activity.parent_id) === p.activity.id),
      x => ({ ...x, activity: activitiesMap[x.activity.id] })
    )
  }));
  return sortBy(result, ["activity.code"]);
});

export const treeWithParentAttribute = createSelector(tree, buildingActivities =>
  flatten(
    buildingActivities.map(({ activity, children, ...rest }) => [
      {
        ...rest,
        activity,
        activityId: activity.id,
        title: activity.title,
        parent: true
      },
      ...children.map(({ activity, children, ...rest }) => ({
        ...rest,
        activity,
        activityId: activity.id,
        title: activity.title,
        parent: false
      }))
    ])
  )
);

export const groupedActivities = createSelector(treeWithParentAttribute, bas => {
  return bas.reduce((prev, curr) => {
    const activityId = curr.activity.parent_id || curr.activity.id;
    return { ...prev, [activityId]: [...(prev[activityId] || []), curr] };
  }, {});
});

export const missingActivities = createSelector(
  buildingDetailSlice,
  slice => slice.missingActivities || []
);
