import React from "react";

import PageLayout from "../../layouts/PageLayout";

import { NotesEditor } from "../../notes";
import * as Messages from "./Messages";

export default () => {
  return (
    <PageLayout title={Messages.AddNote}>
      <NotesEditor />
    </PageLayout>
  );
};
