import { put } from "redux-saga/effects";
import { logout, GET_TOKEN } from "./auth";
import { Deserializer } from "jsonapi-serializer";
import { call } from "redux-saga/effects";
import { postNotification } from "./notifications/ducks/actions"

function onRequestSaga(request, action) {
  if (action.type === GET_TOKEN) {
    return request;
  }
  request.headers = { ...request.headers, TOKEN: sessionStorage.getItem("authToken") };
  return request;
}

function* onSuccessSaga(response, action) {

  if(response.data.error) {
    yield put(postNotification(response.data.error, "error"));
  }

  if (action.meta && action.meta.serialize) {
    const deserialize = data => {
      return new Promise((resolve, reject) => {
        new Deserializer({
          keyForAttribute: "underscore_case",
          ...action.meta.serialize
        }).deserialize(data, (err, json) => {
          if (err) {
            reject(err);
          } else {
            resolve(json);
          }
        });
      });
    };
    const json = yield call(deserialize, response.data);
    return { ...response, data: json };
  }
  return response;
}

function* onErrorSaga(error, action) {
  // do sth here, like dispatch some action

  // you must return { error } in case you dont want to catch error
  // or { error: anotherError }
  // or { response: someRequestResponse } if you want to recover from error

  if (error && error.response && error.response.status === 401) {
    if (!window.location.href.includes("login")) {
      yield put(logout());
    }
  }

  // not related token error, we pass it like nothing happened
  return { error };
}

function* onAbortSaga(action) {
  // do sth, for example an action dispatch
}

export default {
  onRequest: onRequestSaga,
  onSuccess: onSuccessSaga,
  onError: onErrorSaga,
  onAbort: onAbortSaga
};
