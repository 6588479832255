import {
  GET_MANAGERS,
  ADD_NEW_MANAGER,
  GET_MANAGER_BUILDINGS,
  POST_MANAGERS_BUILDING,
  DELETE_MANAGERS_BUILDING
} from "./types";

const RESPONSABILI_COPERNICO = "3";

export const getManagers = ({ referId, userType }) => ({
  type: GET_MANAGERS,
  request: {
    url: "copernico_crm_api/Users/Login/Users.php",
    method: "GET",
    headers: {
      REFERID: referId,
      USERPERMITS: RESPONSABILI_COPERNICO,
      USERTYPE: userType
    }
  }
});

export const getBuildingManagers = ({ buildingId }) => ({
  type: GET_MANAGER_BUILDINGS,
  request: {
    url: `api/internal/v1/ras/building_managers?q[building_id_edificio_eq]=${buildingId}`,
    method: "GET"
  },

  meta: {
    serialize: {
      login_user: {
        valueForRelationship: ({ id }) => ({ id })
      },
      building: {
        valueForRelationship: ({ id }) => ({ id })
      }
    }
  }
});

export const getManagerBuildings = ({ userId }) => ({
  type: GET_MANAGER_BUILDINGS,
  request: {
    url: `api/internal/v1/ras/building_managers?q[user_login_user_id_eq]=${userId}`,
    method: "GET"
  },

  meta: {
    serialize: {
      login_user: {
        valueForRelationship: ({ id }) => ({ id })
      },
      building: {
        valueForRelationship: ({ id }) => ({ id })
      }
    }
  }
});

export const associate = ({ userId, buildingId }) => ({
  type: POST_MANAGERS_BUILDING,
  request: {
    url: "api/internal/v1/ras/building_managers",
    method: "POST",
    data: {
      building_manager: {
        building_id_edificio: buildingId,
        user_login_user_id: userId
      }
    }
  }
});

export const disassociate = ({ buildingManagerId }) => ({
  type: DELETE_MANAGERS_BUILDING,
  request: {
    url: `api/internal/v1/ras/building_managers/${buildingManagerId}`,
    method: "DELETE"
  }
});

export const addNewManager = data => {
  const formData = new FormData();
  formData.set("dataValue", JSON.stringify(data));

  return {
    type: ADD_NEW_MANAGER,
    request: {
      url: "copernico_crm_api/Users/Login/AddUser.php",
      method: "POST",
      data: formData
    }
  };
};
