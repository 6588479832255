export const RAS = "8";
export const SUPERADMIN = "5";
export const ADMINISTRATOR = "1";

export const canRouteAdministratorDashboard = "canRouteAdministratorDashboard";
export const canRouteAdministratorBuildings = "canRouteAdministratorBuildings";
export const canRouteAdministrators = "canRouteAdministrators";
export const canRouteActivities = "canRouteActivities";
export const canRouteManagers = "canRouteManagers";
export const canRouteDashboard = "canRouteDashboard";
export const canRouteBuilding = "canRouteBuilding";
export const canRouteBuildings = "canRouteBuildings";
export const canEditBuildingRasAssigned = "canEditBuildingRasAssigned";
export const canEditBuildingAdministratorAssigned = "canEditBuildingAdministratorAssigned";
export const canAddNotes = "canAddNotes";

export const featuresMap = {
  [RAS]: [
    canRouteAdministratorDashboard,
    canRouteAdministratorBuildings,
    canRouteAdministrators,
    canRouteManagers,
    canRouteDashboard,
    canRouteBuilding,
    canRouteBuildings,
    canEditBuildingRasAssigned,
    canAddNotes
  ],
  [SUPERADMIN]: [
    canRouteAdministratorDashboard,
    canRouteAdministratorBuildings,
    canRouteAdministrators,
    canRouteActivities,
    canRouteDashboard,
    canRouteBuilding,
    canRouteBuildings,
    canAddNotes
  ],
  [ADMINISTRATOR]: [
    canRouteManagers,
    canRouteDashboard,
    canRouteBuilding,
    canRouteBuildings,
    canEditBuildingAdministratorAssigned,
    canAddNotes
  ]
};

export const buildingFeatureMap = {
  ras_assigned: canEditBuildingRasAssigned,
  administrator_assigned: canEditBuildingAdministratorAssigned
};
