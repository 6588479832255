import {
  EVENTS_SHOW_KEY,
  START_NOTES_SAGA,
  END_NOTES_SAGA,
  ADD_NOTE_KEY,
  TOGGLE_NOTE_READ_KEY
} from "./types";

//TODO: remove this action
export const getNotesByBuildingFiscalCode = ({ buildingFiscalCode }) => ({
  type: EVENTS_SHOW_KEY,
  request: {
    url: "/copernico_crm_api/Events/Ras/RasIdFromCodfisc.php",
    method: "GET",
    headers: {
      BUILDINGFISCALCODE: buildingFiscalCode,
      USERIDLOGGED: JSON.parse(sessionStorage.getItem("user")).userId
    }
  }
});

export const startNotesSaga = filters => ({
  type: START_NOTES_SAGA,
  payload: {
    filters
  }
});
export const endNotesSaga = ({ data }) => ({
  type: END_NOTES_SAGA,
  data
});

export const createNote = ({ data }) => {
  const formData = new FormData();
  formData.set("dataValue", JSON.stringify(data));

  return {
    type: ADD_NOTE_KEY,
    request: {
      url: "/copernico_crm_api/Notes/NewNoteRasForRas.php",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData
    }
  };
};

export const toggleNoteRead = ({ eventId, noteId, userId }) => {
  return {
    type: TOGGLE_NOTE_READ_KEY,
    request: {
      url: "/copernico_crm_api/Notes/ReadNotRead.php",
      method: "GET",
      headers: {
        USERIDLOGGED: userId,
        EVENTID: eventId,
        NOTEID: noteId
      }
    }
  };
};
