import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Tooltip } from "@material-ui/core";
import { DeleteOutlined } from "@material-ui/icons";

import * as Messages from "./Messages";

const DeleteDialog = ({ buildingActivity, onDelete: handleDelete }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirmClick = () => {
    handleDelete({ buildingActivity });
    handleClose();
  };

  const handleClose = () => setOpen(false);
  return (
    <>
      <Tooltip title={Messages.DeleteDialogTitle} placement="top">
        <IconButton color="secondary" onClick={handleClickOpen}>
          <DeleteOutlined />
        </IconButton>
      </Tooltip>
      {Boolean(open) && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{Messages.DeleteDialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {Messages.DeleteDialogConfirm(buildingActivity.activity)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {Messages.Cancel}
            </Button>
            <Button onClick={handleConfirmClick} color="primary" autoFocus>
              {Messages.Ok}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeleteDialog;
