import axios from "../../axios";
import { put, take, takeLatest, call, select } from "redux-saga/effects";
import { map } from "lodash";
import { GET_BUILDINGS } from "../../buildings";
import { getBuildings } from "../../buildings";
import { success } from "redux-saga-requests";
import { START_NOTES_SAGA } from "./types";
import { endNotesSaga } from "./actions";
import { userId, token } from "../../auth";

function getNotesByBuildingFiscalCode(buildingFiscalCode, userIdLogged, tokenArg) {
  return axios.request({
    url: "/copernico_crm_api/Events/Ras/RasIdFromCodfisc.php",
    method: "GET",
    headers: {
      token: tokenArg,
      BUILDINGFISCALCODE: buildingFiscalCode,
      USERIDLOGGED: userIdLogged
    }
  });
}

function* handleSaga(action) {
  const { filters } = action.payload;
  yield put(getBuildings(filters));
  const { data: copernicoBuildings } = yield take(success(GET_BUILDINGS));
  const userIdLogged = yield select(userId);
  const tokenArg = yield select(token);
  let notes = {};
  for (const buildingFiscalCode of map(
    copernicoBuildings.buildings,
    building => building.buildingFiscalCode
  )) {
    try {
      const data = yield call(
        getNotesByBuildingFiscalCode,
        buildingFiscalCode,
        userIdLogged,
        tokenArg
      );
      notes = {
        ...notes,
        [buildingFiscalCode]: {
          buildingId: data.data.event[0].condominiumId,
          buildingName: data.data.event[0].condominiumDescription,
          notes: data.data.event[0].notes
        }
      };
    } catch (err) {
      notes = {
        ...notes,
        [buildingFiscalCode]: {
          unauthorized: true,
          notes: []
        }
      };
    }
  }
  yield put(
    endNotesSaga({
      data: notes
    })
  );
}

export default function* notesSaga() {
  yield takeLatest(START_NOTES_SAGA, handleSaga);
}
