/* eslint-disable eqeqeq */
import moment from "moment";
import { createSelector } from "reselect";
import { chain, map, find, includes, filter, reject, groupBy, sumBy, isNull } from "lodash";
import { activitiesMap, allActivities } from "../../activities";

const dashboardSlice = state => state.dashboard;

export const buildings = createSelector(dashboardSlice, activitiesMap, (slice, activitiesMap) => {
  return slice.buildings.map(b => ({
    ...b,
    ras_building_activities: b.ras_building_activities.map(ba => ({
      ...ba,
      activity: activitiesMap[ba.ras_activity.id],
      versions: [
        {
          id: 0,
          event: "update",
          created_at: moment(),
          building_activity: ba
        },
        ...ba.versions
      ]
    }))
  }));
});
export const isLoading = createSelector(dashboardSlice, slice => slice.loading);

export const parentActivities = createSelector(
  allActivities,
  buildings,
  (activities, buildings) => {
    const activityIds = chain(buildings)
      .map(b => b.ras_activities)
      .flatten()
      .map(b => b.id)
      .uniq()
      .value();
    return map(
      activities.filter(x => x.parent_id === null && includes(activityIds, x.id)),
      parentActivity => ({
        ...parentActivity,
        children: activities.filter(activity => activity.parent_id == parentActivity.id)
      })
    );
  }
);

const mapBuilding = ({
  id_edificio,
  nome,
  codfisc,
  assigned_state,
  ras_building_activities,
  unread_note_counters
}) => {
  const parentBuildingActivities = filter(ras_building_activities, {
    activity: { parent_id: null }
  });
  const childrenBuildingActivities = reject(ras_building_activities, {
    activity: { parent_id: null }
  });
  const groupedChildrenBuildingActivities = groupBy(
    childrenBuildingActivities,
    "activity.parent_id"
  );

  return {
    buildingId: id_edificio,
    buildingName: nome,
    buildingAssignedState: assigned_state,
    buildingFiscalCode: codfisc,
    // unreadNotes: unread_note_counters && unread_note_counters[""],
    ...childrenBuildingActivities.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.ras_activity.id]: {
          ...curr,
          status: isNull(curr.expiration_date)
            ? curr.status
            : moment(curr.expiration_date).isBefore()
            ? "expired"
            : moment(curr.expiration_date)
                .subtract(7, "days")
                .isBefore()
            ? "near_to_expire"
            : curr.status,
          // unreadNotes: unread_note_counters && unread_note_counters[curr.ras_activity.id]
          unDoneCorrectiveActions: filter(curr.ras_corrective_actions, { done: false }).length,
          doneCorrectiveActions: filter(curr.ras_corrective_actions, { done: true }).length,
          unVerifiedCorrectiveActions: filter(curr.ras_corrective_actions, (ca)=>{ return ca.done == true && (ca.verified == false || ca.verified == null) }).length,
          verifiedCorrectiveActions: filter(curr.ras_corrective_actions, { done: true, verified: true }).length
        }
      };
    }, {}),
    ...parentBuildingActivities.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.ras_activity.id]: {
          ...curr,
          status: isNull(curr.expiration_date)
            ? curr.status
            : moment(curr.expiration_date).isBefore()
            ? "expired"
            : moment(curr.expiration_date)
                .subtract(7, "days")
                .isBefore()
            ? "near_to_expire"
            : curr.status,
          // unreadNotes: unread_note_counters && unread_note_counters[curr.ras_activity.id]
          unDoneCorrectiveActions: sumBy(
            groupedChildrenBuildingActivities[curr.ras_activity.id].map(ba => {
              return filter(ba.ras_corrective_actions, { done: false }).length;
            })
          ),
          doneCorrectiveActions: sumBy(
            groupedChildrenBuildingActivities[curr.ras_activity.id].map(ba => {
              return filter(ba.ras_corrective_actions, { done: true }).length;
            })
          ),
          unVerifiedCorrectiveActions: sumBy(
            groupedChildrenBuildingActivities[curr.ras_activity.id].map(ba => {
              return filter(ba.ras_corrective_actions, (ca)=>{ return ca.done == true && (ca.verified == false || ca.verified == null) }).length;
            })
          ),
          verifiedCorrectiveActions: sumBy(
            groupedChildrenBuildingActivities[curr.ras_activity.id].map(ba => {
              return filter(ba.ras_corrective_actions, { done: true, verified: true }).length;
            })
          )
        }
      };
    }, {})
    /*
    ...ras_building_activities.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.ras_activity.id]: {
          ...curr,
          // unreadNotes: unread_note_counters && unread_note_counters[curr.ras_activity.id]
          unDoneCorrectiveActions: curr.ras_corrective_actions.length
        }
      };
    }, {})
    */
  };
};

export const dashboard = createSelector(buildings, buildings => map(buildings, mapBuilding));

export const buildingActivitiesByActivityId = (buildingId, activityId) =>
  createSelector(buildings, activitiesMap, (buildings, activitiesMap) => {
    const building = find(buildings, x => x.id_edificio == buildingId);
    if (!building) return [];
    const children = building.ras_building_activities.filter(
      ba => activitiesMap[ba.ras_activity.id].parent_id == activityId
    );
    const parent = {
      ...find(building.ras_building_activities, ba => ba.ras_activity.id == activityId),
      parent: true
    };

    const buildingActivities = [parent, ...children];
    return map(buildingActivities, ba => ({
      ...ba,
      status: isNull(ba.expiration_date)
        ? ba.status
        : moment(ba.expiration_date).isBefore()
        ? "expired"
        : moment(ba.expiration_date)
            .subtract(7, "days")
            .isBefore()
        ? "near_to_expire"
        : ba.status,
      correctiveActions: ba.ras_corrective_actions,
      activity: activitiesMap[ba.ras_activity.id]
    }));
  });
