import React, { useState } from "react";
import moment from "moment";
import { isNull, includes, isEmpty, filter } from "lodash";
import { compose, withHandlers } from "recompose";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Grid,
  makeStyles,
  Divider,
  FormControl,
  Button,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Checkbox,
  Badge
} from "@material-ui/core";
import { ExpandMore, AttachFile, CloudUpload } from "@material-ui/icons";
import { Form, Formik, Field } from "formik";
import { Feature } from "@paralleldrive/react-feature-toggles";
import * as Yup from "yup";
import Dropzone from "react-dropzone-uploader";

import { saveBuildingActivity, updateCorrectiveAction, addDocument, getDocuments } from "./../ducks/actions";
import Status from "../../shared/Status";
import CorrectiveActionsTable from "./CorrectiveActionsTable";
import BuildingActivityUploader from "./BuidlingActivityUploaderContainer";
import DatePickerField from "./DatePickerField";
import * as Messages from "./Messages";
import * as Features from "./../../features";

const useStyles = makeStyles(theme => ({
  statusIcon: {
    marginRight: "16px"
  },
  required: {
    color: "red",
    borderColor: "red",
    cursor: "pointer"
  },
  notRequired: {
    color: "blue",
    borderColor: "blue",
    cursor: "pointer"
  },
  yesButton: {
    padding: "0 4px",
    minWidth: "auto",
    marginLeft: 4,
    color: "white",
    borderColor: "green",
    backgroundColor: "green",
    boxShadow: "none",
    '&:hover': {
      color: "green"
    }
  },
  noButton: {
    padding: "0 4px",
    minWidth: "auto",
    marginLeft: 4,
    color: "white",
    borderColor: "red",
    backgroundColor: "red",
    boxShadow: "none",
    '&:hover': {
      color: "red"
    }
  },
  toBeIntegratedButton: {
    padding: "0 4px",
    minWidth: "auto",
    marginLeft: 4,
    color: "white",
    borderColor: "orange",
    backgroundColor: "orange",
    boxShadow: "none",
    '&:hover': {
      color: "orange"
    }
  },
  disabledButton: {
    padding: "0 4px",
    minWidth: "auto",
    marginLeft: 4,
    color: "white",
    borderColor: "gray",
    backgroundColor: "gray",
    boxShadow: "none",
    '&:hover': {
      color: "gray"
    }
  },
  form: {
    width: "100%"
  },
  customBadge: {
    backgroundColor: "lightblue",
    marginTop: "3px",
    color: "black"
  }
}));

const BuildingActivityDateSchema = Yup.object().shape({
  execution_date: Yup.mixed().test("execution_date", Messages.DateErrorMessage, value => {
    return isNull(value) || value.isValid();
  }),
  expiration_date: Yup.mixed().test("expiration_date", Messages.DateErrorMessage, value => {
    return isNull(value) || value.isValid();
  })
});

export const BuildingActivity = ({
  buildingActivity,
  buildingAssignedState,
  authentication,
  onSave,
  updateCorrectiveAction,
  addDocument,
  onRefresh
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const fullBuildingActivity = (
    <ExpansionPanel expanded={expanded} onChange={handleChange}>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item>
              <div className={classes.statusIcon}>
                <Badge
                  badgeContent={filter(buildingActivity.correctiveActions, (ca)=>{ return ca.done == true && (ca.verified == false || ca.verified == null) }).length}
                  classes={{ badge: classes.customBadge }}>
                    <Status
                      value={buildingActivity.status}
                      required={buildingActivity.activity.required}
                      fontSize="large"
                    />
                </Badge>
              </div>
            </Grid>
            <Grid item className={classes.fillRow}>
              <Grid container direction="column" justify="center" alignItems="flex-start">
                <Grid item>
                  <Typography
                    variant="caption"
                    className={classes[buildingActivity.activity.required ? "required" : "notRequired"]}
                  >
                    <strong>{buildingActivity.activity.title}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {buildingActivity.activity.question}
                    &nbsp;
                    <Feature
                      name={Features.canEditBuildingRasAssigned}
                      activeComponent={() => {
                        return (
                          <>
                            <Button
                              onClick={event => {
                                if (expanded) event.stopPropagation();
                                onSave({
                                  buildingActivity: { id: buildingActivity.id, status: "yes" }
                                });
                              }}
                              variant="outlined"
                              // variant={buildingActivity.status == "yes" ? "contained" : "outlined"}
                              // disabled={buildingActivity.status == "yes"}
                              size="small"
                              className={classes[buildingActivity.status == "yes" ? "yesButton" : "disabledButton"]}>
                                {Messages.Yes}
                            </Button>
                            <Button
                              onClick={event => {
                                if (expanded) event.stopPropagation();
                                onSave({ buildingActivity: { id: buildingActivity.id, status: "no" } });
                              }}
                              variant="outlined"
                              // variant={buildingActivity.status == "no" || buildingActivity.status == null ? "contained" : "outlined"}
                              // disabled={buildingActivity.status == "no" || buildingActivity.status == null}
                              size="small"
                              className={classes[buildingActivity.status == "no" || buildingActivity.status == null ? "noButton" : "disabledButton"]}>
                                {Messages.No}
                            </Button>
                            <Button
                              onClick={event => {
                                if (expanded) event.stopPropagation();
                                onSave({
                                  buildingActivity: {
                                    id: buildingActivity.id,
                                    status: "to_be_integrated"
                                  }
                                });
                              }}
                              variant="outlined"
                              // variant={buildingActivity.status == "to_be_integrated" ? "contained" : "outlined"}
                              // disabled={buildingActivity.status == "to_be_integrated"}
                              size="small"
                              className={classes[buildingActivity.status == "to_be_integrated" ? "toBeIntegratedButton" : "disabledButton"]}>
                                {Messages.Partial}
                            </Button>
                          </>
                        );
                      }}
                    />
                  </Typography>  
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {Boolean(expanded) && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <CorrectiveActionsTable
                  onRefresh={onRefresh}
                  buildingActivityId={buildingActivity.id}
                  defaultCorrectiveAction={buildingActivity.activity.default_corrective_action}
                  correctiveActions={buildingActivity.correctiveActions}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="body1">
                  <strong>{Messages.Uploader}</strong>
                </Typography>
                <Divider />
                <BuildingActivityUploader
                  ba={buildingActivity}
                  buildingAssignedState={buildingAssignedState}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.title} variant="body1">
                  <strong>{Messages.ExpirationDateHelper}</strong>
                </Typography>
                <Divider />

                <Formik
                  initialValues={{
                    execution_date: isNull(buildingActivity.execution_date)
                      ? null
                      : moment(buildingActivity.execution_date),
                    expiration_date: isNull(buildingActivity.expiration_date)
                      ? null
                      : moment(buildingActivity.expiration_date),
                    id: buildingActivity.id
                  }}
                  validationSchema={BuildingActivityDateSchema}
                  onSubmit={values => onSave({ buildingActivity: values })}
                >
                  <Feature>
                    {({ features }) => {
                      const editDisabled = !includes(
                        features,
                        Features.buildingFeatureMap[buildingAssignedState]
                      );
                      return (
                        <Form className={classes.form} autoComplete="off">
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              <FormControl disabled={editDisabled} fullWidth>
                                <Field
                                  name="expiration_date"
                                  component={DatePickerField}
                                  format="DD/MM/YYYY"
                                  label={Messages.ExpirationDate}
                                  disabled={editDisabled}
                                  submitOnChange
                                />
                              </FormControl>
                              <Typography variant="caption">
                                {Messages.ExpirationDateHelper}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Feature>
                </Formik>
              </Grid>
            </Grid>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
  );

  const DocumentUploader = ({ onDocumentUploaded }) => {
    const handleSubmit = (file, status, allFiles) => {
      if(status === "removed") return;
      allFiles.forEach(f => {
        onDocumentUploaded(f.file);
        f.remove();
      });
    };
    return (
      <Dropzone
        accept="image/*,audio/*,video/*,.pdf,.doc,.docx,.xls,.xlsx,.csv"
        maxFiles={1}
        inputContent={<CloudUpload/>}
        // SubmitButtonComponent={SubmitButton}
        onChangeStatus={handleSubmit}
        styles={{
          dropzone: {
            overflow: 'hidden',
            minHeight: "48px",
            width: "128px",
          }
        }}
      />
    );
  };

  const lightBuildingActivity = (
    <ExpansionPanel expanded={expanded} onChange={handleChange}>
        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item>
              <div className={classes.statusIcon}>
                <Status
                  value={buildingActivity.status}
                  required={buildingActivity.activity.required}
                  fontSize="large"
                />
              </div>
            </Grid>
            <Grid item className={classes.fillRow}>
              <Typography
                className={classes[buildingActivity.activity.required ? "required" : "notRequired"]}>
                {buildingActivity.activity.title}
                &nbsp;
                {!isEmpty(buildingActivity.documents) && (
                  <Chip
                    variant="outlined"
                    size="small"
                    icon={<AttachFile />}
                    label={buildingActivity.documents.length}
                    className={classes[buildingActivity.activity.required ? "required" : "notRequired"]}
                  />
                )}
                &nbsp;
                {!isEmpty(filter(buildingActivity.correctiveActions, {done: false})) && (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={Messages.CorrectiveActionCount({count: filter(buildingActivity.correctiveActions, {done: false}).length})}
                    className={classes[buildingActivity.activity.required ? "required" : "notRequired"]}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>  
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {Boolean(expanded) && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Typography variant="body1">
                  <strong>{Messages.CorrectiveAction}</strong>
                </Typography>
                <Divider />
                <List dense className={classes.root}>
                  {
                    buildingActivity.correctiveActions.map((ca) => {
                      return (
                        <ListItem key={ca.id} button>
                          
                          <ListItemIcon>
                            <Feature
                                name={Features.buildingFeatureMap[buildingAssignedState]}
                                activeComponent={() => (
                                    <Checkbox
                                      onChange={() => updateCorrectiveAction({
                                        correctiveActionId: ca.id,
                                        correctiveAction: { done: !ca.done }
                                      })}
                                      checked={ca.done}
                                    />
                                )}
                                inactiveComponent={() => (
                                  <Checkbox
                                      disabled
                                      checked={ca.done}
                                    />
                                )}/>
                            </ListItemIcon>
                          <ListItemText 
                            id={ca.id} 
                            primary={ca.body}
                            style={{
                              paddingRight: 128
                            }}/>
                          <ListItemSecondaryAction>
                              <Feature
                                name={Features.buildingFeatureMap[buildingAssignedState]}
                                activeComponent={() => (
                                    <DocumentUploader onDocumentUploaded={(file) => {
                                      addDocument({buildingActivityId: buildingActivity.id, correctiveActionId: ca.id, file})}
                                    } />
                                )}
                              />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })
                  }
                  {
                    isEmpty(buildingActivity.correctiveActions)
                      ? <Typography>{Messages.NoCorrectiveAction}</Typography>
                      : null
                  }
                </List>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="body1">
                  <strong>{Messages.Uploader}</strong>
                </Typography>
                <Divider />
                <BuildingActivityUploader
                  ba={buildingActivity}
                  buildingAssignedState={buildingAssignedState}
                  callback={() => { onRefresh && onRefresh() }}
                />
              </Grid>
            </Grid>
          )}
        </ExpansionPanelDetails>
    </ExpansionPanel>    
  );

  return authentication.userType == 1
    ? lightBuildingActivity
    : fullBuildingActivity;
};

export default compose(
  connect(state => ({
    authentication: state.auth.authentication
  }), 
  dispatch => ({
    actions: bindActionCreators({ saveBuildingActivity, updateCorrectiveAction, addDocument, getDocuments }, dispatch)
  })),
  withHandlers({
    onSave: ({ actions, onRefresh }) => ({ buildingActivity }) =>
      actions.saveBuildingActivity({ buildingActivity }).then(() => onRefresh && onRefresh()),
    updateCorrectiveAction: ({ actions, onRefresh }) => ({ correctiveActionId, correctiveAction }) => 
      actions.updateCorrectiveAction({ correctiveActionId, correctiveAction }).then(() => onRefresh && onRefresh()),
    addDocument: ({ actions, onRefresh }) => ({ buildingActivityId, correctiveActionId, file }) =>
      actions.addDocument({ buildingActivityId, correctiveActionId, file }).then(() => {
        actions.updateCorrectiveAction({ correctiveActionId, correctiveAction: { done: true } }).then(() => onRefresh && onRefresh());
        actions.getDocuments(buildingActivityId);
      })
  })
)(BuildingActivity);
