import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { Attachment, Launch } from "@material-ui/icons";
import {
  EditingState,
  PagingState,
  IntegratedPaging,
  RowDetailState
} from "@devexpress/dx-react-grid";
import { PagingPanel, TableRowDetail } from "@devexpress/dx-react-grid-material-ui";
import { chain } from "lodash";
import moment from "moment";

import TableComponentBase from "../../shared/TableComponentBase";
import * as Messages from "./Messages";
import { Note } from "./Note";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";

const defaultColumns = [
  {
    name: "creationDate",
    title: Messages.Date,
    getCellValue: note => {
      const creationDate = moment(note.creationDate);
      const format =
        moment().diff(creationDate, "days") === 0
          ? "HH:mm"
          : moment().year() === creationDate.year()
          ? "DD MMM"
          : "DD/MM/YY";
      return creationDate.format(format);
    }
  },
  {
    name: "senderOffice",
    title: Messages.Sender,
    getCellValue: note =>
      chain([note.senderOffice, note.senderName])
        .compact()
        .join(" - ")
        .value()
  },
  {
    name: "buildingName",
    title: "Condominio"
    // getCellValue: note => <Link to={`/buildings/${note.buildingId}`}>
    // </Link>
  },
  {
    name: "activity",
    title: "Impianto",
    getCellValue: note => (note.activity || {}).title
  },
  {
    name: "attachmentCount",
    title: " ",
    getCellValue: note => (note.attachmentCount > 0 ? <Attachment /> : null)
  }
];

const navigableColumns = [
  {
    name: "openNote",
    title: " ",
    getCellValue: note => (
      <Link to={`/buildings/${note.buildingId}?activityId=${note.activity.id}`}>
        <IconButton>
          <Launch />
        </IconButton>
      </Link>
    )
  }
];

const NoteRowDetail = ({ row }) => <Note note={row} />;

const useRowStyle = isRead =>
  makeStyles(() => ({
    row: {
      "& td": {
        fontWeight: isRead === "0" && "bold"
      }
    }
  }));

const Row = ({ row, ...restProps }) => {
  const classes = useRowStyle(row.isRead);
  return <Table.Row {...restProps} className={classes.row} />;
};

const NotesTableComponent = ({ notes, navigable }) => {
  const [columnExtensions] = useState([
    { columnName: "attachmentCount", width: 80 },
    { columnName: "creationDate", width: 100 },
    { columnName: "openNote", width: 80 }
  ]);
  return (
    <Grid
      rows={notes}
      columns={navigable ? [...defaultColumns, ...navigableColumns] : defaultColumns}
    >
      <PagingState defaultCurrentPage={0} pageSize={10} />
      <EditingState />
      <RowDetailState />
      <IntegratedPaging />
      <Table
        tableComponent={TableComponentBase}
        rowComponent={Row}
        columnExtensions={columnExtensions}
      />
      <TableHeaderRow />
      <PagingPanel />
      <TableRowDetail contentComponent={NoteRowDetail} />
    </Grid>
  );
};

export default NotesTableComponent;
