import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Tooltip
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import Add from "@material-ui/icons/Add";
import { Formik } from "formik";
import * as Yup from "yup";
import NewManagerForm from "./NewManagerForm";
import * as Messages from "./Messages";

const NewManagerSchema = Yup.object().shape({
  userName: Yup.string().required(Messages.FieldRequired),
  name: Yup.string().required(Messages.FieldRequired),
  email: Yup.string()
    .email(Messages.WrongEmail)
    .required(Messages.FieldRequired)
});

const NewManagerDialog = ({ user, actions }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [initialValues] = useState({
    referId: user.referId,
    userType: user.userType,
    userIdLogged: user.userId,
    userPermits: "3",
    color: "#000",
    type: "ADMIN",
    recEmail: "1"
  });

  const handleSubmit = (data, { resetForm }) =>
    actions
      .addNewManager(data)
      .then((response) => {
        if(!response.data.error) {
          actions
            .getManagers({ referId: user.referId, userType: user.userType })
            .then(() => {
              closeDialog();
              resetForm();
            });
        }
      })

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  return (
    <>
      <Tooltip placement="top" title={Messages.NewManager}>
        <Button variant="contained" color="primary" onClick={openDialog}>
          <Add />
        </Button>
      </Tooltip>

      <Formik
        initialValues={initialValues}
        validationSchema={NewManagerSchema}
        onSubmit={handleSubmit}
      >
        {formProps => (
          <Dialog open={open} fullWidth maxWidth="sm" fullScreen={fullScreen}>
            <DialogTitle>{Messages.NewManager}</DialogTitle>
            <DialogContent>
              <NewManagerForm {...formProps} />
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={formProps.handleSubmit}>
                {Messages.Add}
              </Button>
              <Button 
                type="reset" 
                onClick={() => {
                  formProps.resetForm();
                  closeDialog();
                }}>
                {Messages.Cancel}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default NewManagerDialog;
