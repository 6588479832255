import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose, lifecycle } from "recompose";
import { startNotesSaga } from "../ducks/actions";
import NotesListComponent from "./NotesListComponent";

export const enhance = compose(
  connect(undefined, dispatch => bindActionCreators({ startNotesSaga }, dispatch)),
  lifecycle({
    componentDidMount() {
      const { startNotesSaga } = this.props;
      startNotesSaga();
    }
  })
);

export default enhance(NotesListComponent);
