import React, { useState } from "react";
import { Grid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import TableComponentBase from "../../shared/TableComponentBase";
import * as Messages from "./Messages";
import BuildingAssociationsDialog from "./BuildingAssociationsDialog";

const ManagerTable = ({ managers }) => {
  const [columns] = useState([
    {
      name: "action",
      title: " ",
      getCellValue: row => <BuildingAssociationsDialog userId={row.userId} />
    },
    {
      name: "username",
      title: Messages.Username
    },
    {
      name: "name",
      title: Messages.FullName
    },
    {
      name: "email",
      title: Messages.Email
    }
  ]);

  const [columnExtensions] = useState([
    {
      columnName: "action",
      width: 80
    }
  ]);

  return (
    <Grid rows={managers} columns={columns}>
      <Table tableComponent={TableComponentBase} columnExtensions={columnExtensions} />
      <TableHeaderRow />
    </Grid>
  );
};

export default ManagerTable;
