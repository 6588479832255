import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Title = <FormattedMessage id="building_detail.title" defaultMessage="Edificio" />;

export const BuildingNotFound = (
  <FormattedMessage id="building_detail.building_not_found" defaultMessage="Edificio non trovato" />
);

export const SelectActivity = (
  <FormattedMessage id="building_detail.select_activity" defaultMessage="Seleziona impianto..." />
);

export const AddActivity = (
  <FormattedMessage id="building_detail.add_activity" defaultMessage="Aggiungi impianto" />
);

export const ToggleAssignmentDialogTitle = (
  <FormattedMessage
    id="building_detail.toggle_assignment_dialog_text"
    defaultMessage="Assegnazione compilazione RAS"
  />
);
export const PrintReportTitle = (
  <FormattedMessage id="building_detail.print_report" defaultMessage="Stampa report edificio" />
);
export const NotesTitle = (
  <FormattedMessage id="building_detail.notes" defaultMessage="Visualizza note edificio" />
);

export const AdministratorAssignmentTooltip = (
  <FormattedMessage
    id="building_detail.administrator_assignment_tooltip"
    defaultMessage="Assegna allo Studio di Amministrazione."
  />
);

export const RasAssignmentTooltip = (
  <FormattedMessage
    id="building_detail.administrator_assignment_tooltip"
    defaultMessage="Assegna al Coordinatore."
  />
);

export const AdministratorAssignmentDialogText = (
  <FormattedMessage
    id="building_detail.administrator_assignment_dialog_text"
    defaultMessage="La compilazione del RAS dell'edificio verrà assegnata allo Studio di Amministrazione."
  />
);
export const RasAssignmentDialogText = (
  <FormattedMessage
    id="building_detail.ras_assignment_dialog_text"
    defaultMessage="La compilazione del RAS dell'edificio verrà assegnata al Coordinatore."
  />
);
export const Cancel = <FormattedMessage id="building_detail.cancel" defaultMessage="Annulla" />;
export const Confirm = <FormattedMessage id="building_detail.confirm" defaultMessage="Conferma" />;
export const Building = (
  <FormattedMessage id="building_detail.building" defaultMessage="Condominio" />
);
export const Manager = (
  <FormattedMessage id="building_detail.manager" defaultMessage="Responsabile" />
);
export const NoManager = (
  <FormattedMessage id="building_detail.no_manager" defaultMessage="Nessun Responsabile" />
);
export const OpenManagerAssignment = (
  <FormattedMessage
    id="buildings_detail.open_manager_assignment"
    defaultMessage="Cambia responsabile"
  />
);
