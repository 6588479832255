import { createSelector } from "reselect";
import { isEmpty, isNull, sortBy } from "lodash";
import moment from "moment";

const getSlice = state => state.buildingActivities;

export const documents = buildingActivityId =>
  createSelector(getSlice, buildingActivities => {
    return buildingActivities.documents[buildingActivityId];
  });

export const buildingActivities = createSelector(getSlice, ({ buildingActivities }) => {
  return isEmpty(buildingActivities.data)
    ? []
    : sortBy(buildingActivities.data, "ras_activity.code");
});

export const buildingActivitiesByBuildingId = buildingId =>
  createSelector(buildingActivities, buildingActivities =>
    buildingActivities
      .filter(ba => ba.building === buildingId)
      .map(ba => ({
        ...ba,
        status: isNull(ba.expiration_date)
          ? ba.status
          : moment(ba.expiration_date).isBefore()
          ? "expired"
          : moment(ba.expiration_date)
              .subtract(7, "days")
              .isBefore()
          ? "near_to_expire"
          : ba.status,
        correctiveActions: ba.ras_corrective_actions
      }))
  );
