import React from "react";
import {
  Dashboard,
  HomeWork,
  // ExitToApp,
  FormatListNumbered,
  SupervisedUserCircle,
  GroupAdd
} from "@material-ui/icons";
import { FormattedMessage } from "react-intl.macro";
import * as Features from "../features";
export default {
  upper: [
    {
      path: "/dashboard",
      icon: Dashboard,
      labelId: () => <FormattedMessage id="sidebar.dashboard" defaultMessage="Dashboard" />
    },
    {
      path: "/administrators",
      icon: SupervisedUserCircle,
      labelId: () => (
        <FormattedMessage id="sidebar.administrators" defaultMessage="Amministratori" />
      ),
      feature: Features.canRouteAdministrators
    },
    {
      path: "/buildings",
      icon: HomeWork,
      labelId: () => <FormattedMessage id="sidebar.buildings" defaultMessage="Edifici" />
    },
    {
      path: "/managers",
      icon: GroupAdd,
      labelId: () => <FormattedMessage id="sidebar.managers" defaultMessage="Responsabili" />,
      feature: Features.canRouteManagers
    },
    {
      path: "/activities",
      icon: FormatListNumbered,
      labelId: () => <FormattedMessage id="sidebar.activities" defaultMessage="Attività" />,
      feature: Features.canRouteActivities
    }
  ],
  lower: [
    /*
    {
      path: "/logout",
      icon: ExitToApp,
      labelId: () => <FormattedMessage id="sidebar.logout" defaultMessage="Logout" />
    }
    */
  ]
};
