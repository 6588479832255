import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import * as Messages from "./Messages";

export const LoginForm = ({ classes, rememberMe, ...rest }) => {
  const {
    values: { email, password },
    errors,
    touched,
    handleChange,
    isValid,
    handleSubmit,
    setFieldTouched
  } = rest;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        autoFocus={true}
        variant="outlined"
        margin="normal"
        fullWidth
        name="email"
        value={email}
        helperText={touched.email ? errors.email : ""}
        onChange={change.bind(null, "email")}
        error={touched.email && Boolean(errors.email)}
        label={Messages.Email}
        id="email"
        data-testid="email"
      />
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="password"
        value={password}
        helperText={touched.password ? errors.password : ""}
        onChange={change.bind(null, "password")}
        error={touched.password && Boolean(errors.password)}
        label={Messages.Password}
        type="password"
        id="password"
        data-testid="password"
      />
      {rememberMe && (
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={Messages.RememberMe}
        />
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!isValid}
        data-testid="btn-login"
      >
        {Messages.Enter}
      </Button>
    </form>
  );
};
