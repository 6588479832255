import { connect } from "react-redux";
import { compose } from "recompose";

import { loadingNotes } from "../ducks/selectors";
import NotesListItems from "./NotesListItemsComponent";

export const enhance = compose(
  connect(state => ({
    loading: loadingNotes(state)
  }))
);

export default enhance(NotesListItems);
