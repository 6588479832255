import { connect } from "react-redux";
import { getAdministrator, administratorDetail } from "../administrators";
import { compose, lifecycle } from "recompose";
import { bindActionCreators } from "redux";

export default compose(
  connect(
    state => ({ administrator: administratorDetail(state) }),
    dispatch => bindActionCreators({ getAdministrator }, dispatch)
  ),
  lifecycle({
    componentDidMount() {
      const { getAdministrator, match } = this.props;
      getAdministrator({ administratorId: match.params.id });
    }
  })
);
