import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Dialog, TextField, makeStyles, Typography, Tooltip } from "@material-ui/core";
import { Formik } from "formik";
import Dropzone from "react-dropzone-uploader";
import { Add } from "@material-ui/icons";

import * as Messages from "./Messages";
import { bindActionCreators } from "redux";
import { createNote, getNotesByBuildingFiscalCode } from "../ducks/actions";
import { rasEventId } from "../ducks/selectors";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: 32
  },
  dropzoneContainer: {
    width: "100%",
    textAlign: "center",
    marginBottom: 16
  }
}));

const dropzoneStyles = { dropzone: { overflow: "unset", paddingBottom: 16 } };

function createFilePromise(file) {
  return new Promise(resolve => {
    let reader = new FileReader();

    reader.readAsDataURL(file.file);

    reader.onload = function() {
      return resolve({
        binary: reader.result.split(",")[1],
        name: file.file.name
          .split(".")
          .slice(0, -1)
          .join("."),
        ext: file.file.name
          .split(".")
          .slice(1, 2)
          .join(".")
      });
    };
  });
}

export function prepareData({ eventId, description, files, activityIdRas }) {
  let data = {
    eventId,
    userIdLogged: JSON.parse(sessionStorage.getItem("user")).userId,
    descr: description,
    tags: "",
    activityIdRas
  };

  if (files.length === 0) {
    return data;
  }

  const filePromises = files.map(createFilePromise);
  return Promise.all(filePromises).then(files => {
    return { ...data, files };
  });
}

const NoteHandler = ({ title, activityId, eventId, actions, buildingFiscalCode }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleToggleNote = value => () => setOpen(value);

  const handleFileUpload = (handleChange, files) => value => {
    if (value.meta.status === "done") {
      files[value.meta.id] = value;
    } else if (value.meta.status === "removed") {
      delete files[value.meta.id];
    }
    handleChange({ target: { name: "files", value: files } });
  };

  const handleSubmit = async ({ files, ...values }) => {
    const data = await prepareData({
      files: Object.values(files),
      ...values,
      eventId,
      activityIdRas: activityId
    });
    actions
      .createNote({ data })
      .then(() => handleToggleNote(false)())
      .then(() => actions.getNotesByBuildingFiscalCode({ buildingFiscalCode }));
  };

  return (
    <>
      <Tooltip placement="top" title={title}>
        <Button onClick={handleToggleNote(true)} variant="contained" color="primary">
          <Add />
        </Button>
      </Tooltip>
      {open && (
        <Dialog open={open} onClose={handleToggleNote(false)} maxWidth="sm" fullWidth>
          <Formik
            initialValues={{ noteType: "", description: "", files: {} }}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, handleChange, values }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Typography component="h4" variant="h4">
                  {Messages.NewNote}
                </Typography>
                <TextField
                  onChange={handleChange}
                  name="description"
                  label={Messages.Description}
                  variant="outlined"
                  margin="normal"
                  rows={8}
                  multiline
                  fullWidth
                />
                <div className={classes.dropzoneContainer}>
                  <Dropzone
                    name="files"
                    className={classes.dropzone}
                    styles={dropzoneStyles}
                    accept="image/*,audio/*,video/*,.pdf,.doc,.docx,.xls,.xlsx,.csv"
                    inputContent={Messages.DropzoneHelperText}
                    inputWithFilesContent={Messages.DropzoneAddFile}
                    submitButtonContent={Messages.DropzoneUpload}
                    onChangeStatus={handleFileUpload(handleChange, values.files)}
                    SubmitButtonContent={null}
                  />
                </div>
                <Button type="submit" variant="contained" color="primary">
                  {Messages.Add}
                </Button>
              </form>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
};

export default connect(
  state => ({ eventId: rasEventId(state) }),
  dispatch => ({
    actions: bindActionCreators(
      {
        createNote,
        getNotesByBuildingFiscalCode
      },
      dispatch
    )
  })
)(NoteHandler);
