import React, { useState, useEffect } from "react";

import NotesListItems from "./NotesListItemsContainer";
import Pagination from "../../shared/Pagination";

const PaginatedNotesListItemsComponent = ({ filters, notes, ...restProps }) => {
  const [page, setPage] = useState(1);
  const [size] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const resetPage = () => {
    setPage(1);
  };

  useEffect(resetPage, [filters]);

  return (
    <Pagination data={notes} page={page} size={size} handleChangePage={handleChangePage}>
      {({ pageData }) => <NotesListItems notes={pageData} {...restProps} />}
    </Pagination>
  );
};

export default PaginatedNotesListItemsComponent;
