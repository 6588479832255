import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import NotesTable from "./NotesTableContainer";
import NotesFilter from "./NotesFilterContainer";
import PaginatedNotesListItems from "./PaginatedNotesListItemsContainer";

const NotesList = ({ showFilters, navigable, listMode, filters: filterProp = {} }) => {
  const [filters, setFilters] = useState(filterProp);

  const handleFilterChange = filters => {
    setFilters(filters);
  };

  return (
    <Grid container spacing={3}>
      {showFilters && (
        <Grid item xs={12}>
          <NotesFilter filters={filters} onFilterChange={handleFilterChange} />
        </Grid>
      )}
      <Grid item xs={12}>
        {listMode ? (
          <PaginatedNotesListItems navigable={navigable} filters={filters} />
        ) : (
          <NotesTable navigable={navigable} filters={filters} />
        )}
      </Grid>
    </Grid>
  );
};

export default NotesList;
