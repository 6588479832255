import { compose, withHandlers } from "recompose";
import { connect } from "react-redux";
import { find } from "lodash";
import NotesFormComponent from "./NotesFormComponent";
import { buildings } from "../../buildings";

const enhance = compose(
  connect(state => ({ buildings: buildings(state) })),
  withHandlers({
    onFileUpload: ({ handleChange }) => files => value => {
      if (value.meta.status === "done") {
        files[value.meta.id] = value;
      } else if (value.meta.status === "removed") {
        delete files[value.meta.id];
      }
      handleChange({ target: { name: "files", value: files } });
    },
    onBuildingSelectChange: ({ buildings, onBuildingChange }) => ({ buildingId }) => {
      onBuildingChange(find(buildings, { buildingId }));
    }
  })
);

export default enhance(NotesFormComponent);
