import { success } from "redux-saga-requests";
import { BUILDING_ACTIVITY_DOCUMENT_GET } from "./types";

const initialState = {};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case success(BUILDING_ACTIVITY_DOCUMENT_GET):
      return {
        ...state,
        [action.meta.buildingActivityId]: action.data.documents
      };
    default:
      return state;
  }
};

export default documentsReducer;
