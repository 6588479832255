import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BuildingActivityDialogComponent from "./BuildingActivityDialogComponent";
import { buildingActivitiesByActivityId } from "../ducks/selectors";
import { deleteBuildingActivity } from "./../../building-activities";
export default connect(
  (state, props) => ({
    buildingActivities: buildingActivitiesByActivityId(props.buildingId, props.activityId)(state)
  }),
  dispatch => ({ actions: bindActionCreators({ deleteBuildingActivity }, dispatch) })
)(BuildingActivityDialogComponent);
