import React from "react";
import BaseLayout from "../../layouts/BaseLayout";
import ManagerTable from "./ManagerTable";
import { Paper, Grid, makeStyles } from "@material-ui/core";
import NewManagerDialog from "./NewManagerDialogContainer";

const useStyles = makeStyles(theme => ({
  actionBar: {
    padding: theme.spacing(3)
  }
}));

const ManagerList = ({ managers }) => {
  const classes = useStyles();
  const handleEditManager = console.log;

  return (
    <BaseLayout title="Responsabili">
      <Paper className={classes.paper}>
        <Grid container item justify="flex-end" className={classes.actionBar}>
          <NewManagerDialog />
        </Grid>
        <ManagerTable managers={managers} onManagerEdit={handleEditManager} />
      </Paper>
    </BaseLayout>
  );
};
export default ManagerList;
