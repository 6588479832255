import React from 'react';
import { Paper, Select, MenuItem, IconButton, Tooltip } from '@material-ui/core';
import { EditingState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableEditRow, TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import { Cancel, Save, Add, Edit, Delete } from '@material-ui/icons';

import * as Messages from './Messages';

const ParentTypeProvider = ({ parentActivities, ...rest }) => {
  const ParentFormatter = ({ value }) =>
    value && parentActivities.length > 0
      ? parentActivities.find((pa) => pa.id === String(value)).title
      : Messages.ParentNotFound;

  const ParentEditor = ({ value, onValueChange }) => (
    <Select value={value || ''} displayEmpty onChange={(event) => onValueChange(event.target.value)} fullWidth>
      <MenuItem value="">{Messages.ParentNotFound}</MenuItem>
      {parentActivities.map((pa) => (
        <MenuItem key={pa.id} value={pa.id}>
          {pa.title}
        </MenuItem>
      ))}
    </Select>
  );
  return <DataTypeProvider formatterComponent={ParentFormatter} editorComponent={ParentEditor} {...rest} />;
};

const iconCommandComponent = {
  add: {
    Icon: Add,
    tooltip: Messages.Add
  },
  edit: {
    Icon: Edit,
    tooltip: Messages.Edit
  },
  delete: {
    Icon: Delete,
    tooltip: Messages.Delete
  },
  cancel: {
    Icon: Cancel,
    tooltip: Messages.Cancel
  },
  commit: {
    Icon: Save,
    tooltip: Messages.Save
  }
};

const IconCommandComponent = ({ id, onExecute }) => {
  const { Icon, tooltip } = iconCommandComponent[id];
  return (
    <Tooltip title={tooltip} placement="top">
      <IconButton color="primary" onClick={onExecute} size="small">
        <Icon />
      </IconButton>
    </Tooltip>
  );
};
const columns = [
  { name: 'code', title: Messages.Code },
  {
    name: 'parent_id',
    title: Messages.ParentActivity
  },
  { name: 'title', title: Messages.Title },
  { name: 'description', title: Messages.Description }
];

const getRowId = ({ id }) => id;

export default ({ activities, parentActivities, onReload, onCreate, onUpdate, onDelete }) => {
  const handleChanges = ({ added, changed, deleted }) => {
    added && added.forEach((val) => onCreate({ activity: val }));
    changed && Object.keys(changed).forEach((id) => onUpdate({ activity: { ...changed[id], id } }));
    deleted && deleted.forEach((val) => onDelete({ id: val }));
  };

  return (
    <Paper>
      <Grid rows={activities} columns={columns} getRowId={getRowId}>
        <ParentTypeProvider for={['parent_id']} parentActivities={parentActivities} />
        <EditingState onCommitChanges={handleChanges} />
        <Table />
        <TableHeaderRow />
        <TableEditRow />
        <TableEditColumn commandComponent={IconCommandComponent} showAddCommand showEditCommand showDeleteCommand />
      </Grid>
    </Paper>
  );
};
