import React from "react";
import PropTypes from "prop-types";
import { slice } from "lodash";
import Paginator from "./Paginator";

const Pagination = ({ data = [], page, size, handleChangePage, children }) => {
  const pageData = slice(data, (page - 1) * size, page * size);

  return (
    <>
      {children({ pageData })}
      <Paginator count={data.length} size={size} page={page} onChangePage={handleChangePage} />
    </>
  );
};

Pagination.propTypes = {
  children: PropTypes.func.isRequired
};

export default Pagination;
