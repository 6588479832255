import React from "react";
import { Formik } from "formik";

import NotesForm from "./NotesFormContainer";

export default ({ buildingId, activityId, onBuildingChange, onSubmit }) => {
  return (
    <Formik
      initialValues={{
        buildingId: buildingId || "",
        activityId: activityId || "",
        description: "",
        files: []
      }}
      onSubmit={onSubmit}
    >
      {formikProps => <NotesForm onBuildingChange={onBuildingChange} {...formikProps} />}
    </Formik>
  );
};
