import React, { useEffect, useState } from "react";
import {
  Grid,
  TableHeaderRow,
  Table,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
  TableFixedColumns
} from "@devexpress/dx-react-grid-material-ui";
import { IntegratedSorting, SortingState } from "@devexpress/dx-react-grid";
import TableComponentBase from "./TableComponentBase";
import { IconButton, Badge } from "@material-ui/core";
import { Link } from "react-router-dom";
import { indexOf, map } from "lodash";

import AssignedState from "./../../shared/AssignedState";

import Status from "../../shared/Status";
import * as Messages from "./Messages";
// import { NotesListModal } from "../../notes";
// import AssignedStateTypeProvider from "../../shared/AssignedStateTypeProvider";
// import DashboardActions from "./DashboardActions";

export default ({ buildings, parentActivities, classes, onBuildingActivitySelected, userType }) => {
  const [columns, setColumns] = useState([]);
  const [leftColumns] = useState(["assignedState", "buildingId", "actions"]);
  const [columnExtensions, setColumnExtensions] = useState([]);
  // const [buildingId, setBuildingId] = useState(null);

console.log(buildings)

  useEffect(() => {
    const buildingColumn = {
      name: "buildingId",
      title: Messages.Building,
      getCellValue: row => (
        <Link to={`/buildings/${row.buildingId}`}>
          {row.buildingName}
        </Link>
      )
    };

    const activityColumns = parentActivities.map(a => {
      return {
        name: a.id,
        title: a.title,
        getCellValue: row => {
          if (!row[a.id]) {
            return (
              <IconButton size="small" disabled>
                <Status value={"missing"} />
              </IconButton>
            );
          } else {
            const childrenStatus = map(a.children, activity => row[activity.id].status);
            let status = "yes";
            if (indexOf(childrenStatus, "to_be_integrated") >= 0) status = "to_be_integrated";
            if (indexOf(childrenStatus, "no") >= 0 || indexOf(childrenStatus, null) >= 0)
              status = "no";
            if (indexOf(childrenStatus, "near_to_expire") >= 0) status = "near_to_expire";
            if (indexOf(childrenStatus, "expired") >= 0) status = "expired";

            return (
              <IconButton
                size="small"
                onClick={() => onBuildingActivitySelected({ buildingActivity: row, activity: a })}
              >
                {
                  userType == "1" // amministratore cond.
                    ? (
                      <Badge
                        //badgeContent={row[a.id].unreadNotes}
                        badgeContent={row[a.id].unDoneCorrectiveActions}
                        classes={{ badge: classes.customBadge }}>
                        <Status value={status} />
                      </Badge>
                    )
                    : (
                      <Badge
                        badgeContent={row[a.id].unVerifiedCorrectiveActions}
                        classes={{ badge: classes.customBadge }}>
                        <Status value={status} />
                      </Badge>
                    )
                }
                
              </IconButton>
            );
          }
        }
      };
    });

    /*
    const actionColumn = {
      name: "actions",
      title: Messages.Actions,
      getCellValue: row => (
        <DashboardActions
          buildingId={row.buildingId}
          //unreadNotes={row.unreadNotes}
          //onNotesClick={() => setBuildingId(row.buildingId)}
        />
      )
    };
    */

    const statusColumn = {
      name: "status",
      title: Messages.Status,
      getCellValue: row => (
        <AssignedState assignedState={row.buildingAssignedState || row.assigned_state}/>
      )
    };

    setColumns([buildingColumn, statusColumn, ...activityColumns]);

    const buildingExtension = {
      columnName: "buildingId",
      width: 250
    };

    const activityExtensions = parentActivities.map(x => ({
      align: "center",
      columnName: x.id,
      width: 80,
      wordWrapEnabled: true
    }));
    /*
    const actionExtension = {
      align: "center",
      columnName: "actions",
      width: 80,
      wordWrapEnabled: true
    };
    */
    const statusExtension = {
      align: "center",
      columnName: "status",
      width: 80,
      wordWrapEnabled: true
    };
    setColumnExtensions([buildingExtension, statusExtension, ...activityExtensions]);
  }, [parentActivities, classes, onBuildingActivitySelected]);

  return (
    <>
      <Grid rows={buildings} columns={columns}>
        <SortingState defaultSorting={[{ columnName: "buildingName", direction: "asc" }]} />
        {/*<AssignedStateTypeProvider for={["buildingId"]} />*/}
        <IntegratedSorting />
        <Table tableComponent={TableComponentBase} columnExtensions={columnExtensions} />
        <TableHeaderRow />
        <TableFixedColumns leftColumns={leftColumns} />
        <TableColumnVisibility columnTogglingEnabled columnExtensions={columnExtensions} />
        <Toolbar />
        <ColumnChooser />
      </Grid>
      {/*
      <NotesListModal
        buildingId={buildingId}
        open={Boolean(buildingId)}
        onClose={() => setBuildingId(null)}
      />
      */}
    </>
  );
};
