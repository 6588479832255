import React from "react";
import { Typography, Grid } from "@material-ui/core";
// import { makeStyles } from "@material-ui/styles";
import { BuildingActivity } from "../../building-activities";
import Modal from "../../modal/Modal";
import { isEmpty } from "lodash";
import { Feature } from "@paralleldrive/react-feature-toggles";
import * as Features from "./../../features";
import DeleteDialog from "../../building-activities/components/DeleteDialog";
// const useStyles = makeStyles(theme => ({
//   paper: {
//     padding: theme.spacing(3, 2)
//   }
// }));

export default ({
  buildingActivities,
  activity,
  buildingId,
  buildingName,
  buildingAssignedState,
  actions,
  buildingFiscalCode,
  onClose: handleClose,
  onRefresh: handleRefresh
  // onSave: handleSave,
  // onDelete: handleDelete
}) => {
  // const classes = useStyles();
  const handleDelete = ({ buildingActivity }) =>
    actions
      .deleteBuildingActivity({ buildingActivityId: buildingActivity.id })
      .then(() => handleClose && handleClose())
      .then(() => handleRefresh && handleRefresh());
  return (
    <Modal
      onClose={handleClose}
      open={Boolean(buildingId) && !isEmpty(buildingActivities)}
      title={
        <>
          {buildingName}&nbsp;<small>{activity.title}</small>
        </>
      }
    >
      {/* <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={12} p={2}>
          <Paper className={classes.paper}>
            <Typography variant="h6" component="h3">
              {activity.description}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <BuildingActivity
            buildingActivity={buildingActivity}
            buildingAssignedState={buildingAssignedState}
            buildingFiscalCode={buildingFiscalCode}
            onDelete={handleDelete}
            onSave={handleSave}
          />
        </Grid>
      </Grid> */}
      {buildingActivities.map(ba => {
        if (ba.parent) {
          return (
            <Grid container justify="space-between" alignItems="center" key={ba.id}>
              <Grid item>
                <Typography variant="h6">
                  <strong>{ba.activity.code}</strong>&nbsp;
                  {ba.title}&nbsp;
                  <small>{ba.activity.description}</small>
                </Typography>
              </Grid>

              <Grid item>
                <Feature
                  name={Features.buildingFeatureMap[buildingAssignedState]}
                  activeComponent={() => (
                    <DeleteDialog buildingActivity={ba} onDelete={handleDelete} />
                  )}
                />
              </Grid>
            </Grid>
          );
        }

        return (
          <BuildingActivity
            key={`BuildingActivity_${ba.id}`}
            buildingAssignedState={buildingAssignedState}
            buildingFiscalCode={buildingFiscalCode}
            buildingActivity={ba}
            requestedActivityId={false}
            onRefresh={handleRefresh}
          />
        );
      })}
    </Modal>
  );
};
