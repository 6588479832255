import React from "react";
import Dropzone from "react-dropzone-uploader";
import { makeStyles } from "@material-ui/styles";
import { Grid, TextField, FormControl, InputLabel, Button } from "@material-ui/core";

import * as Messages from "./Messages";
import { Field } from "formik";
import { BuildingsSelect } from "../../buildings";
import { BuildingActivitiesSelect } from "../../building-activities";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: 32
  },
  dropzoneContainer: {
    width: "100%",
    textAlign: "center",
    marginBottom: 16
  }
}));

const dropzoneStyles = { dropzone: { overflow: "unset", paddingBottom: 16 } };

export default ({ onBuildingSelectChange, onFileUpload, values, handleSubmit, handleChange }) => {
  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>{Messages.BuildingSelect}</InputLabel>
            <Field
              component={BuildingsSelect}
              name="buildingId"
              onSelectChange={onBuildingSelectChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink>{Messages.BuildingActivitySelect}</InputLabel>
            <Field
              buildingId={values.buildingId}
              component={BuildingActivitiesSelect}
              name="activityId"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={handleChange}
            name="description"
            value={values.description}
            variant="outlined"
            margin="normal"
            rows={8}
            multiline
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.dropzoneContainer}>
            <Dropzone
              name="files"
              className={classes.dropzone}
              styles={dropzoneStyles}
              accept="image/*,audio/*,video/*,.pdf,.doc,.docx,.xls,.xlsx,.csv"
              inputContent={Messages.DropzoneHelperText}
              inputWithFilesContent={Messages.DropzoneAddFile}
              submitButtonContent={Messages.DropzoneUpload}
              onChangeStatus={onFileUpload(values.files)}
              SubmitButtonContent={null}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={!values.buildingId}
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            {Messages.Save}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
