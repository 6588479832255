import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { bindActionCreators } from "redux";
import { getBuildings } from "../../buildings";
import { getManagerBuildings, associate, disassociate } from "../ducks/actions";
import { buildingAssociations } from "../ducks/selectors";
import BuildingAssociationsTable from "./BuildingAssociationsTable";

const enhance = compose(
  connect(
    state => ({
      buildingAssociations: buildingAssociations(state)
    }),
    dispatch =>
      bindActionCreators(
        {
          getBuildings,
          getManagerBuildings,
          associate,
          disassociate
        },
        dispatch
      )
  ),
  lifecycle({
    componentDidMount() {
      const { userId, getBuildings, getManagerBuildings } = this.props;
      getBuildings().then(() => getManagerBuildings({ userId }));
    }
  }),
  withHandlers({
    onAssociationChange: ({ userId, associate, disassociate, getManagerBuildings }) => ({
      selected,
      buildingId,
      buildingManagerId
    }) => {
      let promise;
      if (selected) {
        promise = associate({ buildingId, userId });
      } else {
        promise = disassociate({ buildingManagerId });
      }
      promise.then(() => getManagerBuildings({ userId }));
    }
  })
);

export default enhance(BuildingAssociationsTable);
