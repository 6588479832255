import React, { useState } from "react";
import { Grid, Table, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import TableComponentBase from "../../shared/TableComponentBase";
import { Checkbox } from "@material-ui/core";

const BuildingAssociationsTable = ({ buildingAssociations = [], onAssociationChange }) => {
  const [columns] = useState([
    {
      name: "selected",
      title: "Associato",
      getCellValue: row => (
        <Checkbox
          checked={row.selected}
          onChange={evt => {
            const input = {
              ...row,
              selected: evt.target.checked
            };
            onAssociationChange(input);
          }}
        />
      )
    },
    {
      name: "buildingName",
      title: "Edificio"
    }
  ]);

  return (
    <Grid rows={buildingAssociations} columns={columns}>
      <Table tableComponent={TableComponentBase} />
      <TableHeaderRow />
    </Grid>
  );
};

export default BuildingAssociationsTable;
