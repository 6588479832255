import { connect } from "react-redux";
import { compose } from "recompose";
import { allNotes } from "../ducks/selectors";
import PaginatedNotesListItemsComponent from "./PaginatedNotesListItemsComponent";

const enhance = compose(
  connect((state, { filters }) => ({
    notes: allNotes(filters)(state)
  }))
);

export default enhance(PaginatedNotesListItemsComponent);
