import React from "react";
import { FormattedMessage } from "react-intl.macro";

export const Add = <FormattedMessage id="event_handling.add" defaultMessage="Aggiungi" />;

export const Notes = <FormattedMessage id="event_handling.notes" defaultMessage="Note" />;

export const NewNote = <FormattedMessage id="event_handling.title" defaultMessage="Nuova nota" />;

export const Type = <FormattedMessage id="event_handling.type" defaultMessage="Tipo" />;

export const OpenNote = (
  <FormattedMessage id="event_handling.open_note" defaultMessage="Apri nota" />
);

export const Description = (
  <FormattedMessage id="event_handling.description" defaultMessage="Descrizione" />
);
export const BuildingSelect = (
  <FormattedMessage id="event_handling.building_select" defaultMessage="Seleziona edificio" />
);
export const BuildingActivitySelect = (
  <FormattedMessage
    id="event_handling.building_activity_select"
    defaultMessage="Seleziona attività"
  />
);
export const Save = <FormattedMessage id="event_handling.save" defaultMessage="Salva" />;
export const None = <FormattedMessage id="event_handling.none" defaultMessage="Nessuna" />;
export const DropzoneHelperText = (
  <FormattedMessage
    id="event_handling.drop_helper_text"
    defaultMessage="Trascina il documento da caricare o clicca per selezionare un file"
  />
);

export const DropzoneAddFile = (
  <FormattedMessage id="event_handling.add_file" defaultMessage="Aggiungi file" />
);

export const DropzoneUpload = (
  <FormattedMessage id="event_handling.upload" defaultMessage="Carica" />
);

export const GenericNotesTitle = (
  <FormattedMessage
    id="event_handling.generic_notes_title"
    defaultMessage="Note generali per l'edificio"
  />
);

export const Sender = <FormattedMessage id="event_handling.sender" defaultMessage="Mittente" />;

export const Date = <FormattedMessage id="event_handling.date" defaultMessage="Data" />;

export const Descritpion = (
  <FormattedMessage id="event_handling.description" defaultMessage="Testo" />
);

export const AttachmentsCount = (
  <FormattedMessage id="event_handling.attachments_count" defaultMessage="Numero Allegati" />
);
export const BuildingNotesOnly = (
  <FormattedMessage
    id="event_handling.building_notes_only"
    defaultMessage="Mostra solo note generali per edificio"
  />
);
export const UnreadNotesOnly = (
  <FormattedMessage
    id="event_handling.unread_notes_only"
    defaultMessage="Mostra solo note non lette"
  />
);
