import React from "react";
import { FormattedMessage } from "react-intl.macro";
import * as SharedMessages from "../../shared/Messages";

export const Editor = <FormattedMessage id="building_activity.editor" defaultMessage="Modifica" />;
export const Uploader = (
  <FormattedMessage id="building_activity.uploader" defaultMessage="Allegati" />
);
export const Notes = (
  <FormattedMessage id="building_activity.notes" defaultMessage="Note e Messaggi" />
);
export const History = <FormattedMessage id="building_activity.history" defaultMessage="Storico" />;

export const ExecutionDate = (
  <FormattedMessage id="building_activity.execution_date" defaultMessage="Data di esecuzione" />
);
export const ExpirationDate = (
  <FormattedMessage id="building_activity.expiration_date" defaultMessage="Data di scadenza" />
);
export const UpdateDate = (
  <FormattedMessage id="building_activity.update_date" defaultMessage="Data di aggiornamento" />
);

export const Status = <FormattedMessage id="building_activity.status" defaultMessage="Status" />;
export const StatusSource = (
  <FormattedMessage id="building_activity.status_source" defaultMessage="Stato corrente" />
);
export const StatusDestination = (
  <FormattedMessage id="building_activity.status_destination" defaultMessage="Nuovo stato" />
);

export const Done = <FormattedMessage id="building_activity.done" defaultMessage="Si" />;
export const NearToExpire = (
  <FormattedMessage id="building_activity.near_to_expire" defaultMessage="In scadenza" />
);
export const Expired = <FormattedMessage id="building_activity.expired" defaultMessage="Scaduto" />;

export const Undone = <FormattedMessage id="building_activity.undone" defaultMessage="No" />;

export const ToDo = <FormattedMessage id="building_activity.to_do" defaultMessage="Da integrare" />;

export const None = <FormattedMessage id="building_activity.none" defaultMessage="Nessuno" />;

export const AddNote = (
  <FormattedMessage id="building_activity.add_note" defaultMessage="Aggiungi nota" />
);

export const DropzoneHelperText = (
  <FormattedMessage
    id="building_activity.dropzone_helper_text"
    defaultMessage="Trascina il documento da caricare o clicca per selezionare un file"
  />
);

export const DropzoneAdd = (
  <FormattedMessage id="building_activity.dropzone_add" defaultMessage="Aggiungi" />
);
export const Delete = <FormattedMessage id="building_activity.delete" defaultMessage="Cancella" />;
export const Ok = <FormattedMessage id="building_activity.ok" defaultMessage="Ok" />;
export const Cancel = <FormattedMessage id="building_activity.cancel" defaultMessage="Annulla" />;
export const NoDocument = (
  <FormattedMessage id="building_activity.no_document" defaultMessage="Non ci sono documenti" />
);
export const NoCorrectiveAction = (
  <FormattedMessage id="building_activity.no_corrective_action" defaultMessage="Non ci sono azioni correttive da adempiere" />
);
export const DeleteDialogTitle = (
  <FormattedMessage
    id="building_activity.delete_dialog_title"
    defaultMessage="Rimozione capitolo"
  />
);
export const DeleteDialogConfirm = ({ title }) => (
  <FormattedMessage
    id="building_activity.delete_dialog_confirm"
    defaultMessage={`Eliminare definitivamente l'attività {title}?`}
    values={{ title }}
  />
);

export const FieldRequired = SharedMessages.FieldRequired;

export const DateErrorMessage = (
  <FormattedMessage
    id="building_activity.date_error_message"
    defaultMessage="La data deve essere nel formato GG/MM/AAAA"
  />
);

export const Description = (
  <FormattedMessage id="building_activity.description" defaultMessage="Descrizione" />
);

export const Add = <FormattedMessage id="building_activity.add" defaultMessage="Aggiungi" />;

export const Edit = <FormattedMessage id="building_activity.edit" defaultMessage="Modifica" />;

export const Save = <FormattedMessage id="building_activity.save" defaultMessage="Salva" />;

export const CorrectiveActionDone = (
  <FormattedMessage id="building_activity.corrective_action_done" defaultMessage="Completata" />
);

export const CorrectiveActionVerified = (
  <FormattedMessage id="building_activity.corrective_action_valid" defaultMessage="Verificata" />
);

export const CorrectiveAction = (
  <FormattedMessage id="building_activity.corrective_action" defaultMessage="Azioni Correttive" />
);
export const CorrectiveActionCount = ({count}) => (
  <FormattedMessage id="building_activity.corrective_action_count" defaultMessage="Azioni Correttive {count}" values={{count}} />
);
export const ExecutionDateHelper = (
  <FormattedMessage
    id="building_activity.execution_date_helper"
    defaultMessage="Data di esecuzione"
  />
);
export const ExpirationDateHelper = (
  <FormattedMessage
    id="building_activity.expiration_date_helper"
    defaultMessage="Data di revisione"
  />
);
export const Yes = <FormattedMessage id="building_activity.yes" defaultMessage="SI" />;
export const No = <FormattedMessage id="building_activity.no" defaultMessage="NO" />;
export const Partial = (
  <FormattedMessage id="building_activity.partial" defaultMessage="PARZIALE" />
);
