/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

export default ({ logout }) => {
  useEffect(() => {
    logout();
  }, [logout]);

  return <Redirect to="login" />;
};
