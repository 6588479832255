import React, { useState } from "react";
import { Paper, LinearProgress, Grid } from "@material-ui/core";

import PageLayout from "../../layouts/PageLayout";
import { makeStyles } from "@material-ui/styles";
import * as Messages from "./Messages";
import BuildingActivityDialog from "./BuildingActivityDialogContainer";
import DashboardTable from "./DashboardTable";
import DashboardFilters from "./DashboardFilters";
import AdministratorCard from "../../shared/AdministratorCard";

const useStyles = makeStyles(theme => ({
  customBadge: {
    backgroundColor: "lightblue",
    marginTop: "3px",
    color: "black"
  },
  tableRoot: {
    marginTop: theme.spacing(3),
    "& th, & td": {
      borderLeft: "solid 1px lightgray"
    },
    "& th:first-child, & td:first-child, & th:nth-child(3), & td:nth-child(3)": {
      //borderLeft: "none"
    },
    "& thead": {
      "& th": {
        "&:first-child": {
          "& span": {
            writingMode: "horizontal-tb"
          }
        },
        "& span": {
          height: "160px",
          writingMode: "vertical-rl",
          lineHeight: "16px",
          fontSize: "12px",
          fontWeight: "bold"
        }
      }
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

export default ({ buildings, parentActivities, isLoading, actions, match = {}, administrator, userType }) => {
  const classes = useStyles();

  const [parentBuildingActivity, setParentBuildingActivity] = useState(null);

  const [query, setQuery] = useState({
    "q[with_not_all_ras_building_activities]": "yes",
    // "q[for_building_manager]": userId,
    "q[id_amm_eq]": (match.params || {}).id
  });

  const showDialog = ({ buildingActivity, activity }) => {
    setParentBuildingActivity({
      buildingId: buildingActivity.buildingId,
      buildingName: buildingActivity.buildingName,
      buildingAssignedState: buildingActivity.buildingAssignedState,
      buildingFiscalCode: buildingActivity.buildingFiscalCode,
      activity: activity,
      activityId: activity.id,
      building: buildingActivity.building
    });
  };
  const closeDialog = () => setParentBuildingActivity(null);

  // const handleDelete = buildingActivityId =>
  //   actions
  //     .deleteBuildingActivity({ buildingActivityId })
  //     .then(() => actions.startDashboardSaga(query))
  //     .then(closeDialog);

  // const handleSave = buildingActivity =>
  //   actions
  //     .saveBuildingActivity({ buildingActivity })
  //     .then(() => actions.startDashboardSaga(query));

  const handleFilterChange = newFilters => {
    setQuery(newFilters);
    actions.startDashboardSaga(newFilters);
  };

  const administratorId = (match.params || {}).id;

  return (
    <PageLayout
      title={
        <>
          {Messages.Dashboard} {administrator && administrator.brand}
        </>
      }
    >
      <Grid container>
        {administrator && (
          <Grid item xs={12} className={classes.content}>
            <AdministratorCard administrator={administrator} />
          </Grid>
        )}
        <Grid item xs={12} className={classes.content}>
          <DashboardFilters
            query={query}
            onFilterChange={handleFilterChange}
            administratorId={administratorId}
          />
        </Grid>
        <Grid item xs={12}>
          <main className={classes.content}>
            <Paper className={classes.tableRoot}>
              {isLoading && <LinearProgress />}
              {!isLoading && (
                <DashboardTable
                  userType={userType}
                  classes={classes}
                  buildings={buildings}
                  parentActivities={parentActivities}
                  onBuildingActivitySelected={showDialog}
                />
              )}
            </Paper>
          </main>
        </Grid>
      </Grid>
      {parentBuildingActivity && (
        <BuildingActivityDialog
          {...parentBuildingActivity}
          onClose={closeDialog}
          onRefresh={() => actions.startDashboardSaga(query)}
          // onDelete={handleDelete}
          // onSave={handleSave}
        />
      )}
    </PageLayout>
  );
};
