import React from "react";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { fade } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  customRow: {
    "& tbody tr:nth-of-type(odd)": {
      backgroundColor: fade(theme.palette.primary.main, 0.05)
    },
    "& tbody tr:hover": {
      backgroundColor: "#e3e3e3"
    }
  }
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.customRow} size="small" />
);
export default withStyles(styles, { name: "TableComponentBase" })(TableComponentBase);
