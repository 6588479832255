import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Menu as MenuIcon,
  AccountCircle,
  ExitToApp,
  // FIXME re-enable notes Add,
  // FIXME re-enable notes Message,
  ChevronLeft
} from "@material-ui/icons";
import { Feature } from "@paralleldrive/react-feature-toggles";
import sidebar from "../__config__/sidebar";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  ListSubheader,
  CssBaseline,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Container,
  Grid,
  Menu,
  MenuItem,
  Tooltip,
  // FIXME re-enable notes Hidden
} from "@material-ui/core";

import { LanguageSelector } from "../i18n";
import { FormattedMessage } from "react-intl.macro";
import { userName } from "../auth/ducks/selectors";
/* FIXME re-enable notes
import * as Messages from "./Messages";
import { NotesWell, NotesListModal } from "../notes";
*/

const menuDrawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: menuDrawerWidth,
    width: `calc(100% - ${menuDrawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  menuDrawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: menuDrawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuDrawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "100%",
    width: "100%"
  },
  lowerMenu: {
    position: "absolute",
    bottom: 0,
    width: "100%"
  }
}));

const SideButton = ({ item }) => (
  <ListItem button component={Link} to={item.path} key={item.path}>
    <Tooltip placement="right" title={<item.labelId />}>
      <ListItemIcon>
        <item.icon />
      </ListItemIcon>
    </Tooltip>
    <ListItemText primary={<item.labelId />} />
  </ListItem>
);

const LogoutMenu = ({ anchorEl, onClose }) => (
  <Menu
    anchorEl={anchorEl}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    keepMounted
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    open={Boolean(anchorEl)}
    onClose={onClose}
  >
    <MenuItem onClick={onClose} component={Link} to="/logout">
      <ListItemIcon>
        <ExitToApp />
      </ListItemIcon>
      <ListItemText primary={<FormattedMessage id="sidebar.logout" defaultMessage="Logout" />} />
    </MenuItem>
  </Menu>
);

const SideMenuButton = ({ item }) =>
  item.feature ? (
    <Feature
      name={item.feature}
      activeComponent={() =>
        item.children ? (
          <div>
            <Divider />
            <ListSubheader inset>
              <item.labelId />
            </ListSubheader>
            {item.children.map((element, index) => (
              <SideButton key={index} item={element} />
            ))}
          </div>
        ) : (
          <SideButton item={item} />
        )
      }
      inactiveComponent={() => null}
    />
  ) : item.children ? (
    <div>
      <Divider />
      <ListSubheader inset>
        <item.labelId />
      </ListSubheader>
      {item.children.map((element, index) => (
        <SideButton key={index} item={element} />
      ))}
    </div>
  ) : (
    <SideButton item={item} />
  );

export const ProfileSection = connect(state => ({ userName: userName(state) }))(
  ({ userName, onMenuOpen }) => (
    <>
      <IconButton edge="end" aria-haspopup="true" onClick={onMenuOpen} color="inherit">
        <AccountCircle />
      </IconButton>
      <span style={{ marginLeft: "5px", cursor: "pointer" }} onClick={onMenuOpen}>
        {userName}
      </span>
    </>
  )
);

export const BaseLayout = ({ children, title }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  /* FIXME re-enable notes
  const [notesModal, setNotesModal] = useState(null);
  */

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /* FIXME re-enable notes
  const handleToggleNotesModal = value => () => {
    setNotesModal(value);
  };
  */

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {title}
            </Typography>
            {/* FIXME re-enable notes
            <Tooltip placement="bottom" title={Messages.AddNote}>
              <IconButton component={Link} to={"/add-note"} color="inherit">
                <Add />
              </IconButton>
            </Tooltip>
            <Hidden lgUp>
              <Tooltip placement="bottom" title={Messages.OpenNotesList}>
                <IconButton color="inherit" onClick={handleToggleNotesModal(true)}>
                  <Message />
                </IconButton>
              </Tooltip>
            </Hidden>
            */}
            <ProfileSection onMenuOpen={handleProfileMenuOpen} />
            <LanguageSelector />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.menuDrawerPaper, !open && classes.menuDrawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          <List>
            {sidebar.upper.map((item, index) => (
              <SideMenuButton key={`sidebar_upper_${index}`} item={item} />
            ))}
          </List>
          <List className={classes.lowerMenu}>
            {sidebar.lower.map((item, index) => (
              <SideMenuButton key={`sidebar_lower_${index}`} item={item} />
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {children}
            </Grid>
          </Container>
        </main>
        <LogoutMenu anchorEl={anchorEl} onClose={handleMenuClose} />
        {/* FIXME re-enable notes
        <NotesWell />
        */}
      </div>
      {/* FIXME re-enable notes
      <NotesListModal open={Boolean(notesModal)} onClose={handleToggleNotesModal(false)} /> 
      */}
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.element.isRequired
};

export default BaseLayout;
