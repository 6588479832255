import { combineReducers } from 'redux';
import { requestsReducer } from 'redux-saga-requests';
import { findIndex, filter } from 'lodash';
import { ACTIVITIES_INDEX } from './types';

export default combineReducers({
  list: requestsReducer({
    actionType: ACTIVITIES_INDEX,
    mutations: {
      ACTIVITIES_DELETE: (state, action) => filter(state.data, (activity) => activity.id !== action.meta.id),
      ACTIVITIES_UPDATE: (state, action) => {
        const index = findIndex(state.data, { id: action.data.data.id });
        state.data[index] = { ...action.data.data.attributes, id: action.data.data.id };
        return state.data;
      },
      ACTIVITIES_CREATE: (state, action) => {
        state.data.push({ ...action.data.data.attributes, id: action.data.data.id, ras_building_activities: [null] });
        return state.data;
      }
    }
  })
});
