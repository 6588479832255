import { ADMINISTRATOR_INDEX, ADMINISTRATOR_SHOW } from "./types";

export const getAdministrators = () => ({
  type: ADMINISTRATOR_INDEX,
  request: {
    url: "/copernico_crm_api/Users/Admins/ListForOperator.php",
    method: "GET"
  }
});

export const getAdministrator = ({ administratorId }) => ({
  type: ADMINISTRATOR_SHOW,
  request: {
    url: "/copernico_crm_api/Users/Admins/DetailForOperator.php",
    method: "GET",
    headers: {
      AMMID: administratorId
    }
  }
});
