import React from 'react';
import { Link } from 'react-router-dom';
import { Paper, Tooltip, IconButton, Badge, makeStyles } from '@material-ui/core';
import { Grid, Table, TableHeaderRow, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { EditingState, FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';

import * as Messages from './Messages';
import TableComponentBase from '../../shared/TableComponentBase';
import PageLayout from '../../layouts/PageLayout';
import { HomeWork } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  customBadge: {
    backgroundColor: "lightblue",
    marginTop: "3px",
    color: "black"
  }
}));  

const columns = [
  {
    name: 'brand',
    title: Messages.Name
  },
  {
    name: 'referent',
    title: Messages.Referent
  },
  {
    name: 'phone',
    title: Messages.Phone
  },
  {
    name: 'email',
    title: Messages.Email
  },
  {
    name: 'city',
    title: Messages.City,
    //getCellValue: (row) => `${row.address}, ${row.city}`
  },
  {
    name: 'address',
    title: Messages.Address,
    //getCellValue: (row) => `${row.address}, ${row.city}`
  }
  // {
  //   name: 'status',
  //   title: Messages.Status
  // }
];

const ActionsCell = ({ row, children, ...rest }) => {
  const classes = useStyles();
  return (
    <Table.Cell {...rest}>
      {/*
      <Tooltip placement="top" title={Messages.OpenAdministratorBuildings}>
        <IconButton color="primary" size="small" to={`/administrators/${row.idAmm}/buildings`} component={Link}>
          <HomeWork />
        </IconButton>
      </Tooltip>
      <Tooltip placement="top" title={Messages.OpenAdministratorDashboard}>
        <IconButton color="primary" size="small" to={`/administrators/${row.idAmm}/dashboard`} component={Link}>
          <Dashboard />
        </IconButton>
      </Tooltip>
      */}
      <Tooltip placement="top" title={Messages.OpenAdministratorDashboard}>
          <IconButton color="primary" size="small" to={`/administrators/${row.idAmm}/dashboard`} component={Link}>
            <Badge
              badgeContent={row.unVerifiedCorrectiveActionCounter}
              classes={{ badge: classes.customBadge }}>
              <HomeWork />
            </Badge>
          </IconButton>
      </Tooltip>
    </Table.Cell>
  )
};

const AdministratorListContent = ({ administrators }) => {

  return (
    <Paper>
      <Grid rows={administrators} columns={columns}>
        <FilteringState />
        <EditingState />
        <IntegratedFiltering />
        <Table tableComponent={TableComponentBase} />
        <TableHeaderRow />
        <TableFilterRow />
        <TableEditColumn showEditCommand cellComponent={ActionsCell} width={80}/>
      </Grid>
    </Paper>
  );
};

const AdministratorListComponent = ({ administrators }) => {
  return (
    <PageLayout title={Messages.Administrators}>
      <AdministratorListContent administrators={administrators} />
    </PageLayout>
  );
};

export default AdministratorListComponent;
