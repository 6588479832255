import React from "react";
import moment from "moment";
import { chain } from "lodash";

import Modal from "../../modal/Modal";
import { Note } from "./Note";

const NoteDialog = ({ note, onClose }) => {
  return (
    <Modal
      open={note}
      title={`Nota di ${chain([note.senderOffice, note.senderName])
        .compact()
        .join(" - ")
        .value()} del ${moment(note.creationDate).format("DD/MM/YYYY")}`}
      onClose={onClose}
    >
      <Note note={note} />
    </Modal>
  );
};

export default NoteDialog;
