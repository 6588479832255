import axios from "axios";
import config from "./config";

const axiosInstance = axios.create({ baseURL: config.apiBaseUrl });

// axiosInstance.interceptors.request.use(request => {
//   request.headers["TOKEN"] = sessionStorage.getItem("authToken");
//   return request;
// });

// axiosInstance.interceptors.response.use(response => {
//   if (response.status === 401) {
//     console.log("unauthenticated session, redirect to login");
//     window.location.href = config.apiBaseUrl + "/#/login";
//     return;
//   }
//   return response;
// });

export default axiosInstance;
