import React from "react";
import { chain } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { blue, green } from "@material-ui/core/colors";
import { Image, Description, BarChart, AttachFile, Assignment } from "@material-ui/icons";
import moment from "moment";
import { List, ListItem, ListItemAvatar, ListItemText, Grid, Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: "20px",
    width: "100%"
  },
  avatar: {
    backgroundColor: blue[500]
  },
  noteText: {
    overflow: "auto",
    height: "160px"
  },
  attachments: {
    overflow: "auto",
    height: "240px",
    padding: "8px 0"
  },
  attachmentAvatar: {
    backgroundColor: green[500]
  }
}));

export function Note({ note }) {
  const classes = useStyles();

  const renderAvatar = ({ fileType }) => {
    if (["jpg", "bmp", "gif", "png"].includes(fileType)) return <Image />;
    if (["doc", "docx", "pdf"].includes(fileType)) return <Description />;
    if (["xls", "xlsx"].includes(fileType)) return <BarChart />;
    return <AttachFile />;
  };

  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item sm={9}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                <Assignment />
              </Avatar>
            }
            title={
              <strong>
                {chain([note.senderOffice, note.senderName])
                  .compact()
                  .join(" - ")
                  .value()}
              </strong>
            }
            subheader={moment(note.creationDate).format("DD MMM YYYY HH:mm")}
          />
          <Divider />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.noteText}
            >
              {note.description}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item sm={3}>
          <CardContent>
            {note.attachments && (
              <List dense className={classes.attachments}>
                {note.attachments.map((attachment, idx) => (
                  <ListItem key={`note_${note.id}_attachment_${idx}`}>
                    <ListItemAvatar>
                      <Avatar className={classes.attachmentAvatar}>
                        {renderAvatar(attachment)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={attachment.fileName}
                      secondary={
                        <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                          Scarica
                        </a>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
