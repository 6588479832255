import React from "react";

import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    background: "#fafafa",
    padding: "16px"
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Modal = ({ children, open, onClose, title }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      onClose={onClose}
      open={Boolean(open)}
    >
      <>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="close" onClick={onClose}>
              <Close />
            </IconButton>
            <Typography variant="h5" component="h3" className={classes.title}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>{children}</div>
      </>
    </Dialog>
  );
};

export default Modal;
