import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { user } from "../../auth";
import NewManagerDialog from "./NewManagerDialog";
import { addNewManager, getManagers } from "../ducks/actions";

const enhance = compose(
  connect(
    state => ({
      user: user(state)
    }),
    dispatch => ({
      actions: bindActionCreators({ addNewManager, getManagers }, dispatch)
    })
  )
);

export default enhance(NewManagerDialog);
