import React from "react";

import { FormattedMessage } from "react-intl.macro";

export const ActivityListTitle = <FormattedMessage defaultMessage="Attività" id="activity_list" />;

export const Code = <FormattedMessage defaultMessage="Codice" id="activity_list.code" />;
export const ParentActivity = (
  <FormattedMessage defaultMessage="Attività principale" id="activity_list.parent_activity" />
);
export const Title = <FormattedMessage defaultMessage="Titolo" id="activity_list.title" />;

export const Description = (
  <FormattedMessage defaultMessage="Descrizione" id="activity_list.description" />
);

export const ParentNotFound = (
  <FormattedMessage defaultMessage="Nessuno" id="activity_list.parent_not_found" />
);

export const Edit = <FormattedMessage defaultMessage="Modifica" id="activity_table.edit" />;

export const Add = <FormattedMessage defaultMessage="Aggiungi" id="activity_table.add" />;

export const Delete = <FormattedMessage defaultMessage="Elimina" id="activity_table.delete" />;

export const Save = <FormattedMessage defaultMessage="Salva" id="activity_table.save" />;

export const Cancel = <FormattedMessage defaultMessage="Annulla" id="activity_table.cancel" />;
